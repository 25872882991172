import { useEffect, useState } from "react";
import { Container, Status } from "./style";
import toast from "react-hot-toast";
import { useAppSelector } from "../../../store/hooks";
import ContentLayout from "../../../layout/Content";
import { HeaderText } from "../Supervisor/style";
import PrimaryTable from "../../../components/Table";
import { PuffLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import {
  useGetBulkPendingMutation,
  useInitiateTransactionMutation,
} from "src/services/clearingService";
import { PuffDiv } from "../Declined/style";
import Header from "../../../components/Header/Header";
import PrimaryButton from "../../../components/Buttons/Button";
import { sortTableData } from "src/utils/sortByDate";

const PendingBulk = () => {
  const navigate = useNavigate();
  const token: any = useAppSelector(
    (state) => state.StaffDataReducer?.staffInfo?.accessToken
  );
  const [pendingBulk, dataMutation] = useGetBulkPendingMutation();
  const [initiateTransaction, initiateMutation] =
    useInitiateTransactionMutation();
  const [selectedBatchId, setBatchId] = useState<string>("");

  const [bulkData, setBulkData] = useState<any>([]);

  const handleGetPendinBulk = async () => {
    try {
      const response = await pendingBulk(token).unwrap();
      if (response?.responseCode === "00") {
        let dd = [...response?.data];
        let sortedArr = dd.sort(sortTableData);
        setBulkData(sortedArr);
      }
    } catch (error) {}
  };
  useEffect(() => {
    handleGetPendinBulk();
  }, []);

  const handleView = async () => {
    navigate(`/transaction/view-batch/${selectedBatchId}/${1}`);
  };

  const handleSubmitTransaction = async () => {
    try {
      const transactionIds = [bulkData?.[0]?.id.toString()];
      const initiateData = {
        batchId: selectedBatchId,
        transactionIds: transactionIds.flat(),
        token: token,
      };
      const response = await initiateTransaction(initiateData).unwrap();

      if (response?.responseCode === "00") {
        toast.success(response?.responseDescription);
      } else if (response?.responseCode === "99") {
        toast.error(response?.responseDescription);
      }
    } catch (error: any) {
      toast.error(error?.data?.responseDescription);
    }
  };

  const btnOptions = [
    { id: 1, title: "View", action: handleView },
    { id: 2, title: "Initiate", action: handleSubmitTransaction },
  ];

  const invalidBtnOptions = [{ id: 1, title: "View", action: handleView }];

  const bulkHeader = [
    "S/N",
    "BatchId",
    "Debit Amount",
    "Credit Amount",
    "Count",
    "Created By",
    "Date",
    "Status",
    "Action",
  ];

  const bulkDataBody = bulkData?.map((data: any, index: number) => [
    index + 1,
    data.batchId,
    new Intl.NumberFormat().format(data.totalDebitAmount),
    new Intl.NumberFormat().format(data.totalCreditAmount),
    data.transactionCount,
    data.createdBy === null ? "NIL" : data.createdBy?.split("@")[0],
    data.createdOn?.substring(0, 10),
    <Status
      key={data.id}
      color={data?.transactionStatus === "VALID" ? "#01B272" : "#C43C20"}
      backgroundColor={
        data?.transactionStatus === "VALID"
          ? "rgba(1, 178, 114, 0.2)"
          : "rgba(231, 175, 164, 0.3)"
      }
    >
      {data?.transactionStatus}
    </Status>,
    <PrimaryButton
      background={"#fff"}
      color={"#5c2684"}
      width={100}
      title={"Action"}
      icon={""}
      action={true}
      openDrop
      rightIcon={true}
      loading={false}
      leftIconName={<></>}
      rightIconName={<></>}
      onClick={() => {
        setBatchId(data?.batchId);
      }}
      leftIcon={false}
      border={"1px solid #5c2684"}
      options={
        data?.transactionStatus === "VALID" ? btnOptions : invalidBtnOptions
      }
    />,
  ]);

  return (
    <>
      <Header title="View Transactions" />
      <ContentLayout>
        <Container>
          <HeaderText>Bulk Transactions</HeaderText>
          {/* <DateWrap>
            <DateComponent
              showModal={false}
              onClosed={() => {}}
              value={selectedStartDate}
              handleDateChange={(e: any) =>
                setSelectedStartDate(e.target.value)
              }
              selectDate={setSelectedStartDate}
              label={"Start date"}
            />

            <DateComponent
              showModal={false}
              onClosed={() => {}}
              value={selectedEndDate}
              handleDateChange={(e: any) => setSelectedEndDate(e.target.value)}
              selectDate={setSelectedEndDate}
              label={"End date"}
            />
          </DateWrap>
          <ButtonWrapper>
            <PrimaryButton
              background={"#5c2684"}
              color={"#fff"}
              width={"150px"}
              title={"Search"}
              loading={false}
              isLoading={isLoading}
              leftIconName={<></>}
              rightIconName={<></>}
              onClick={handleFilter}
              border={""}
            />
          </ButtonWrapper> */}

          {dataMutation.isLoading ? (
            <PuffDiv>
              <PuffLoader />
            </PuffDiv>
          ) : (
            <>
              {bulkData.length !== 0 && (
                <PrimaryTable
                  header={bulkHeader}
                  body={bulkDataBody}
                  arrOfObject={true}
                />
              )}
            </>
          )}
        </Container>
      </ContentLayout>
    </>
  );
};
export default PendingBulk;
