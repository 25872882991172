import { makeUnAuthorizedRequest } from "./request-api";
import { ENDPOINTS } from "src/utils/endpoints";


// this is the auth function that takes in the data,  
export const auth = {
    async loginUser(data: any) {
        const { method, url } = ENDPOINTS.auth.login;

        return makeUnAuthorizedRequest( method, url, data);
    }
}