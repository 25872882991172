import { createSlice } from "@reduxjs/toolkit";

// This slice will hold clearing needed information

const ClearingData = createSlice({
  name: "User data",
  initialState: {
    toggle: false,
  },
  reducers: {
    setToggle: (state, action) => {
      state.toggle = action.payload;
    },
  },
});
export const ClearingDataReducer = ClearingData.reducer;

export const { setToggle } = ClearingData.actions;
