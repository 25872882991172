import { LayoutProps } from "../types/Types";
import { Outlet } from "react-router-dom";
import { Body, BodyLeft, BodyRight, Dashboard } from "./style";
import Sidebar from "../components/StaffSidebar";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useIdleTimer } from "react-idle-timer";

const Layout = ({ children }: LayoutProps) => {
  let staff: { accessToken: string } = JSON.parse(
    localStorage.getItem("staffInfo") || "{}"
  );
  let accessToken = staff.accessToken;
  var options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const BASE_URL = process.env.REACT_APP_BASEURL;

  const notify = () => {
    toast("You have been automatically logged out of the system", {
      style: {
        background: "#ffea00",
      },
    });
  };
  const navigate = useNavigate();
  const onIdle = () => {
    fetch(`${BASE_URL}/user/logout`, options)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log(data)
        localStorage.clear();

        navigate("/login");
      });
  };
  useIdleTimer({
    onIdle,
    timeout: 300_000_000,
    throttle: 500,
  });

  return (
    <Dashboard>
      <Body>
        <BodyLeft>
          <Sidebar />
        </BodyLeft>
        <BodyRight>
          <Outlet />
          {children}
        </BodyRight>
      </Body>
    </Dashboard>
  );
};

export default Layout;
