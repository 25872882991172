import { useEffect, useState } from "react";
import ContentLayout from "src/layout/Content";
import Header from "../../../components/Header/Header";
import { ReactComponent as CloseIcon } from "../../../assets/svg/close.svg";
import {
  Container,
  HeaderText,
  BottomContainer,
  TableContainer,
  ButtonWrap,
  DetailsWrap,
  IconWrap,
  LeftD,
  RightD,
  FieldWrapD,
  Text,
  TopTitle,
  Top,
} from "./style";

import {
  useApproveChargeMutation,
  usePendingChargeApprovalMutation,
} from "src/services/adminService";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import PrimaryTable from "../../../components/Table";
import PrimaryButton from "../../../components/Buttons/Button";
import PuffLoader from "../../../components/Loader";
import GeneralModal from "../../../components/StatusModal/GeneralModal";

const ChargeApproval = () => {
  const token = useSelector(
    (state: any) => state.StaffDataReducer?.staffInfo?.accessToken
  );
  const [pendingList, setpendingList] = useState<any>([]);
  const [id, setId] = useState<number>(0);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [veiwData, setVeiwData] = useState<any>();
  const [pendingCharge, pendingUserMutation] =
    usePendingChargeApprovalMutation();
  const [approveCharge, approveUserMutation] = useApproveChargeMutation();

  const handleGetPendingList = async () => {
    try {
      const response = await pendingCharge().unwrap();

      if (response.responseCode === "00") {
        toast.success(response?.responseDescription);
        setpendingList(response?.data);
      }
    } catch (err: any) {
      toast.error(err?.data?.responseDescription);
    }
  };
  useEffect(() => {
    handleGetPendingList();
  }, []);

  const handleView = (id: number) => {
    setId(id);
    const findData = pendingList?.find((data: any) => data.Id === id);
    setVeiwData(findData);
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };
  const handleApprove = async () => {
    try {
      const requiredData = {
        id: id,
        isApproved: true,
      };

      const response = await approveCharge(requiredData).unwrap();
      if (response.responseCode === "00") {
        toast.success(response?.responseDescription);
        setpendingList([]);
        setOpenModal(false);
        handleGetPendingList();
      }
    } catch (error: any) {
      toast.error(error?.data?.responseDescription);
    }
  };

  const handleDecline = async () => {
    try {
      const requiredData = {
        id: id,
        isApproved: false,
      };
      const response = await approveCharge(requiredData).unwrap();

      if (response.responseCode === "00") {
        toast.success(response?.responseDescription);
        setpendingList([]);
        setOpenModal(false);
        handleGetPendingList();
      }
    } catch (error: any) {
      toast.error(error?.data?.responseDescription);
    }
  };

  const header = [
    "S/N",
    "Charge Amount",
    "FCMB Income",
    "NIBSS Income",
    "New FCMB Income",
    "New NIBSS Income",
    "New VAT",
    "Action",
  ];

  const dataBody = pendingList?.map((item: any, index: number) => [
    index + 1,
    item?.ChargeAmount,
    item?.FCMBIncome,
    item?.NIBSSIncome,
    item?.NewFCMBIncome,
    item?.NewNIBSSIncome,
    item?.NewVat,
    <>
      <PrimaryButton
        key={item.username}
        background={"#fff"}
        color={"#5c2684"}
        width={"100%"}
        title={"View"}
        icon={""}
        rightIcon={true}
        act={false}
        loading={false}
        leftIconName={<></>}
        rightIconName={<></>}
        onClick={() => handleView(item?.Id)}
        leftIcon={false}
        border={"1px solid #5c2684"}
        action={false}
        options={undefined}
        item={item}
      />
    </>,
  ]);

  return (
    <>
      <Header title="Charge Management" />
      <ContentLayout>
        <Container>
          <BottomContainer>
            <HeaderText>Charges</HeaderText>
          </BottomContainer>

          <TableContainer>
            {pendingUserMutation?.isLoading ? (
              <PuffLoader />
            ) : (
              <PrimaryTable
                header={header}
                body={dataBody}
                arrOfObject={true}
              />
            )}
          </TableContainer>
        </Container>

        {openModal && (
          <>
            <GeneralModal general>
              <Top>
                <TopTitle>Other Information</TopTitle>
                <IconWrap>
                  <CloseIcon onClick={handleClose} />
                </IconWrap>
              </Top>

              <FieldWrapD>
                <DetailsWrap>
                  <LeftD>
                    <Text>Minimum Transaction Amount</Text>{" "}
                  </LeftD>
                  <RightD>
                    <Text>
                      {" "}
                      {new Intl.NumberFormat().format(
                        veiwData?.MinimumTransactionAmount
                      )}
                    </Text>{" "}
                  </RightD>
                </DetailsWrap>
                <DetailsWrap>
                  <LeftD>
                    <Text>Maximum Transaction Amount</Text>{" "}
                  </LeftD>
                  <RightD>
                    <Text>
                      {new Intl.NumberFormat().format(
                        veiwData.MaximumTransactionAmount
                      )}
                    </Text>{" "}
                  </RightD>
                </DetailsWrap>
                <DetailsWrap>
                  <LeftD>
                    <Text>New Charge Amount</Text>{" "}
                  </LeftD>
                  <RightD>
                    <Text> {veiwData?.NewChargeAmount}</Text>{" "}
                  </RightD>
                </DetailsWrap>
                <DetailsWrap>
                  <LeftD>
                    <Text>New Minimum Transaction Amount</Text>{" "}
                  </LeftD>
                  <RightD>
                    <Text>
                      {" "}
                      {new Intl.NumberFormat().format(
                        veiwData?.NewMinimumTransactionAmount
                      )}
                    </Text>{" "}
                  </RightD>
                </DetailsWrap>
                <DetailsWrap>
                  <LeftD>
                    <Text>New Maximum Transaction Amount</Text>{" "}
                  </LeftD>
                  <RightD>
                    <Text>
                      {" "}
                      {new Intl.NumberFormat().format(
                        veiwData?.NewMaximumTransactionAmount
                      )}
                    </Text>{" "}
                  </RightD>
                </DetailsWrap>
                <DetailsWrap>
                  <LeftD>
                    <Text>Created By</Text>{" "}
                  </LeftD>
                  <RightD>
                    <Text>{veiwData?.CreatedBy}</Text>{" "}
                  </RightD>
                </DetailsWrap>
              </FieldWrapD>
              <ButtonWrap>
                <PrimaryButton
                  background={"#E21A1A"}
                  color={"#ffffff"}
                  width={140}
                  title={"Decline"}
                  icon={""}
                  loading={false}
                  leftIconName={<></>}
                  rightIconName={<></>}
                  onClick={handleDecline}
                  leftIcon={false}
                  border={"1px solid #E21A1A"}
                />

                <PrimaryButton
                  background={"green"}
                  color={"#ffffff"}
                  width={140}
                  title={"Approve"}
                  icon={""}
                  loading={false}
                  leftIconName={<></>}
                  rightIconName={<></>}
                  onClick={handleApprove}
                  leftIcon={false}
                  border={"1px solid green"}
                />
              </ButtonWrap>
            </GeneralModal>
          </>
        )}
      </ContentLayout>
    </>
  );
};

export default ChargeApproval;
