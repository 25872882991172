import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "src/store/store";
import { baseQuery, baseQueryWithAuth, getHeaders } from ".";

const BASE_URL = process.env.REACT_APP_BASEURL;

interface RoleResponse {
  id: 1;
  roleName: string;
  isActive: boolean;
}

// interface RoleResponse {
//   responseCode: string;
//   responseDescription: string;
//   status: boolean;
//   data: RoleList[] | null;
// }

interface userQuery {
  userName: string;
}

interface postData {
  username: string;
  roleId: number;
}
interface userStruct {
  lastName: string;
  firstName: string;
  email: string;
  displayName: string;
  response: string;
  responseMessage: string;
  userName: string;
  roleName: string;
}
interface userData {
  data: userStruct;
  status?: number;
}

export interface assignStruct {
  // createdBy: null;
  // createdOn: string;
  // id: number;
  // isActive: boolean;
  // lastLogin: string;
  // lastModified?: string;
  // roleId: number;
  // username: string;
  lastName: string;
  firstName: string;
  email: string;
  username: string;
  roleName: string;
  response: string;
  responseMessage: string;
}
interface assignResponseData {
  responseCode?: string;
  responseDescription?: string;
  data?: assignStruct;
  error?: string;
}

export interface transactionResponse {
  responseCode?: string;
  responseDescription?: string;
  status?: boolean;
  data?: Data;
}

export interface Data {
  totalTransactions?: number;
  uploadedTransactions?: number;
  successfulTransactions?: number;
  failedTransactions?: number;
}

interface actionResponse {
  responseCode: string;
  description: string;
  statusCode: number;
  responseDescription: string;
  message: string;
  data: boolean;
}

const localInfo = localStorage.getItem("staffInfo");
const tokenValue = JSON.parse(localInfo || "{}")?.accessToken;

// const customBaseQuery: BaseQueryFn<
//   string | FetchArgs,
//   unknown,
//   FetchBaseQueryError
// > = async (args, api, extraOptions) => {
//   const baseResult = await fetchBaseQuery({
//     baseUrl: BASE_URL,
//     prepareHeaders: (_headers, { getState }) => {
//       const token = (getState() as RootState).StaffDataReducer?.staffInfo
//         ?.accessToken;

//       const headers = getHeaders(_headers);

//       headers.set("Content-Type", "application/json");
//       headers.set("accept", "text/plain");
//       headers.set("Access-Control-Expose-Headers", "access-token");
//       headers.set("Access-Control-Allow-Origin", "*");
//       headers.set("Access-Control-Allow-Methods", "*");
//       if (token) {
//         headers.set("Authorization", `Bearer ${token}`);
//       }
//     },
//   })(args, api, extraOptions);

//   const newResponse: any = {
//     ...baseResult,
//   };
//   const errorCode = newResponse?.data?.statusCode;
//   if (errorCode === 401) {
//     localStorage.clear();
//     window.location.href = "/login";
//   }
//   return baseResult;
// };

export const adminApi = createApi({
  reducerPath: "adminApi",
  baseQuery: baseQueryWithAuth(baseQuery),

  endpoints: (builder) => ({
    //get role
    getAllRoles: builder.query<RoleResponse[], void>({
      query: () => ({
        url: "Admin/role",
        headers: {
          Authorization: `Bearer ${tokenValue}`,
        },
      }),

      transformResponse: (response: { data: RoleResponse[] }, meta, arg) =>
        response.data,
    }),

    //get user
    getUser: builder.mutation<userData, { userName: string }>({
      query: ({ userName }) => ({
        url: `Admin/user/${userName}`,
        method: "GET",
      }),
      // transformResponse: (response: { data: userStruct }, meta, arg) =>
      //   response.data,
    }),

    //get all user
    getAllUsers: builder.query<assignStruct[], void>({
      query: (token) => ({
        url: "Admin/users",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),

      transformResponse: (response: { data: assignStruct[] }, meta, arg) =>
        response.data,
    }),

    //assign role
    assignRole: builder.mutation<assignResponseData, Partial<postData>>({
      query: (body) => ({
        url: "Admin/UserRole",
        method: "POST",
        body,
      }),
    }),

    //activate user
    activateUser: builder.mutation<actionResponse, { username: string }>({
      query: ({ username }) => ({
        url: `Admin/users/${username}/activate`,
        method: "GET",
      }),
      // transformResponse: (response: { data: actionResponse }, meta, arg) =>
      //   response.data,
    }),

    //deactivate user
    deactivateUser: builder.mutation<actionResponse, { username: string }>({
      query: ({ username }) => ({
        url: `Admin/users/${username}/deactivate`,
        method: "GET",
      }),
      // transformResponse: (response: { data: actionResponse }, meta, arg) =>
      //   response.data,
    }),

    //get all charges
    getAllTransactions: builder.query<transactionResponse, void>({
      query: () => ({
        url: "Dashboard/transactionStats",
        headers: {
          Authorization: `Bearer ${tokenValue}`,
        },
      }),
      // transformResponse: (response: { data: transactionResponse }, meta, arg) =>
      //   response.data,
    }),
    //get pending user
    pendingUserApproval: builder.mutation<any, void>({
      query: (token) => ({
        url: "Admin/users-pending-approval",
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    //approve user
    approveUser: builder.mutation<
      any,
      { username: string; isApproved: boolean }
    >({
      query: (body) => ({
        url: "Admin/approval-user",
        method: "POST",
        body,
      }),
    }),

    //get pending charge
    pendingChargeApproval: builder.mutation<any, void>({
      query: (token) => ({
        url: "Charges/charge-pending-approval",
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    //approve charge
    approveCharge: builder.mutation<any, { id: number; isApproved: boolean }>({
      query: (body) => ({
        url: "Charges/approval-charge",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useActivateUserMutation,
  useDeactivateUserMutation,
  useGetAllRolesQuery,
  useGetAllUsersQuery,
  useGetUserMutation,
  useAssignRoleMutation,
  useGetAllTransactionsQuery,
  usePendingUserApprovalMutation,
  useApproveUserMutation,
  usePendingChargeApprovalMutation,
  useApproveChargeMutation,
} = adminApi;
