import React, { Suspense, lazy } from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import Layout from "src/layout/Layout";
import Testing from "src/pages/Testing";
import { CustomHashLoader } from "src/components/LazyLoader";
import { useSelector } from "react-redux";
import Protected from "./Protected";
import PendingBulk from "src/pages/dashboard/bulk-pending";
import UserApproval from "src/pages/dashboard/userApproval";
import ChargeApproval from "src/pages/dashboard/chargeApproval";

const Login = lazy(() => import("src/pages/login/Login"));
const ManageProfile = lazy(() => import("src/pages/dashboard/Manage Profile"));
const Upload = lazy(() => import("src/pages/upload-neft/Upload"));
const Zones = lazy(() => import("src/pages/zone/Zones"));
const Edit = lazy(() => import("src/pages/dashboard/Edit"));
const XML = lazy(() => import("src/pages/dashboard/XML"));
const Create = lazy(() => import("src/pages/dashboard/Create"));
const BankReport = lazy(() => import("src/pages/dashboard/Bank-Wide-Report"));
const BranchReport = lazy(() => import("src/pages/dashboard/Branch-Report"));
const ProfileMgt = lazy(() => import("src/pages/dashboard/Profile-Management"));
const Transactions = lazy(
  () => import("src/pages/dashboard/Transaction-Status")
);
const FundSweep = lazy(() => import("src/pages/dashboard/Sweeping"));
const GeneralReport = lazy(() => import("src/pages/dashboard/General-Report"));
const OutwardNeftSI = lazy(() => import("src/pages/dashboard/OutwardNeft"));
const ChargeUpdate = lazy(() => import("src/pages/dashboard/Charge-Update"));
const Delete = lazy(() => import("src/pages/dashboard/Delete"));
const Dashboard = lazy(() => import("src/pages/dashboard/Dashboard"));
const Reports = lazy(() => import("src/pages/dashboard/Reports"));
const ReportsAMU = lazy(() => import("src/pages/dashboard/Reports-AMU"));
const PendingApproval = lazy(
  () => import("src/pages/dashboard/Pending-Approval")
);
const View = lazy(() => import("src/pages/dashboard/View"));
const ProfileManagement = lazy(
  () => import("src/pages/dashboard/Profile-Management/assignRole")
);
const ViewBatch = lazy(() => import("src/pages/dashboard/Batch"));
const Supervisor = lazy(() => import("src/pages/dashboard/Supervisor"));

const AppRouter = () => {
  const staffInfo = useSelector(
    (state: any) => state.StaffDataReducer?.staffInfo
  );

  return (
    <Suspense fallback={<CustomHashLoader color="#5c2684" size={60} />}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="testing" element={<Testing />} />
        <Route
          path="/"
          element={
            <Protected isVerified={staffInfo}>
              <Layout children={<></>} title={""} />{" "}
            </Protected>
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="home" element={<Dashboard />} />
          <Route path="manage-profile" element={<ManageProfile />} />
          <Route path="upload-neft" element={<Upload />} />
          <Route path="zone" element={<Zones />} />
          <Route path="general-report" element={<GeneralReport />} />
          <Route path="outward-neft" element={<OutwardNeftSI />} />
          <Route path="bankwide-report" element={<BankReport />} />
          <Route path="branch-report" element={<BranchReport />} />
          <Route path="xml-file" element={<XML />} />

          <Route path="create" element={<Create />} />
          <Route path="edit" element={<Edit />} />
          <Route path="clearing" element={<Outlet />}>
            <Route index element={<FundSweep />} />
            <Route path="funds-sweep" element={<FundSweep />} />
          </Route>

          <Route path="transaction" element={<Outlet />}>
            <Route index element={<Upload />} />
            <Route path="upload-neft" element={<Upload />} />
            <Route path="pending-bulk" element={<PendingBulk />} />
            <Route path="view-batch/:batchId/:check" element={<ViewBatch />} />
          </Route>

          <Route path="supervisor" element={<Supervisor />} />
          <Route path="transaction-status" element={<Transactions />} />
          <Route path="profile-management" element={<ProfileMgt />} />
          <Route path="user-approval" element={<UserApproval />} />
          <Route
            path="admin-profile-management"
            element={<ProfileManagement />}
          />
          <Route path="view" element={<View />} />
          <Route path="delete" element={<Delete />} />
          <Route path="upload-si" element={<Upload />} />
          <Route path="charge-update" element={<ChargeUpdate />} />
          <Route path="reports" element={<Reports />} />
          <Route path="reports-amu" element={<ReportsAMU />} />
          <Route path="pending-approval" element={<PendingApproval />} />
          <Route path="view-batch/:batchId/:check" element={<ViewBatch />} />

          <Route path="view-batch/all-transactions" element={<ViewBatch />} />
          <Route
            path="view-batch/initiated-transactions"
            element={<ViewBatch />}
          />
          <Route
            path="view-batch/declined-transactions"
            element={<ViewBatch />}
          />

          {/* clearing report */}
          <Route path="reports" element={<Outlet />}>
            <Route index element={<Reports />} />
            <Route path="general-report" element={<GeneralReport />} />
            <Route path="branch-report" element={<BranchReport />} />
            <Route path="bankwide-report" element={<BankReport />} />
          </Route>

          {/* clearing report */}
          <Route path="approval" element={<Outlet />}>
            <Route index element={<UserApproval />} />
            <Route path="user-approval" element={<UserApproval />} />
            <Route path="charge-approval" element={<ChargeApproval />} />
          </Route>
          {/* tb */}
          <Route path="standing-instruction" element={<Outlet />}>
            <Route index element={<Create />} />
            <Route path="create" element={<Create />} />
            <Route path="edit" element={<Edit />} />
            <Route path="view" element={<View />} />
            <Route path="delete" element={<Delete />} />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
