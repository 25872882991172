// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
* {
  font-family: "Poppins", sans-serif;
}

::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  /* -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; */
}
p {
  margin: 0;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper,
.css-uhb5lp {
  width: 100% !important;
  max-width: 96vw !important;
  align-items: center;
  overflow: visible !important;

  background-color: transparent !important;
  box-shadow: none !important;
}
*:focus {
  outline: none !important;
}

.button__effect {
  transition: 0.3s ease all;
}
.button__effect:hover {
  opacity: 0.9;
}
.button__effect:focus {
  opacity: 0.7;
}
.button__effect:active {
  opacity: 0.9;
  transform: scale(0.95);
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,kCAAkC;AACpC;AACA;EACE,kCAAkC;AACpC;;AAEA;EACE,aAAa,EAAE,sBAAsB;AACvC;AACA;;;EAGE,SAAS;EACT,UAAU;EACV,mBAAmB;EACnB,wCAAwC;EACxC,2BAA2B;EAC3B;;;;sBAIoB;AACtB;AACA;EACE,SAAS;AACX;;AAEA;;EAEE,sBAAsB;EACtB,0BAA0B;EAC1B,mBAAmB;EACnB,4BAA4B;;EAE5B,wCAAwC;EACxC,2BAA2B;AAC7B;AACA;EACE,wBAAwB;AAC1B;;AAEA;EACE,yBAAyB;AAC3B;AACA;EACE,YAAY;AACd;AACA;EACE,YAAY;AACd;AACA;EACE,YAAY;EACZ,sBAAsB;AACxB","sourcesContent":["html {\n  box-sizing: border-box;\n  font-family: \"Poppins\", sans-serif;\n}\n* {\n  font-family: \"Poppins\", sans-serif;\n}\n\n::-webkit-scrollbar {\n  display: none; /* Safari and Chrome */\n}\n*,\n*::before,\n*::after {\n  margin: 0;\n  padding: 0;\n  box-sizing: inherit;\n  -webkit-tap-highlight-color: transparent;\n  -webkit-touch-callout: none;\n  /* -webkit-user-select: none;\n  -khtml-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none; */\n}\np {\n  margin: 0;\n}\n\n.css-1t1j96h-MuiPaper-root-MuiDialog-paper,\n.css-uhb5lp {\n  width: 100% !important;\n  max-width: 96vw !important;\n  align-items: center;\n  overflow: visible !important;\n\n  background-color: transparent !important;\n  box-shadow: none !important;\n}\n*:focus {\n  outline: none !important;\n}\n\n.button__effect {\n  transition: 0.3s ease all;\n}\n.button__effect:hover {\n  opacity: 0.9;\n}\n.button__effect:focus {\n  opacity: 0.7;\n}\n.button__effect:active {\n  opacity: 0.9;\n  transform: scale(0.95);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
