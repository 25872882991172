import React from "react";
import {
  Container,
  ImageContainer,
  LeftContainer,
  LeftHeader,
  RightContainer,
  UserContainer,
  Username,
} from "./style";

const Header = ({ title = "Dashboard" }) => {
  const localInfo = JSON.parse(localStorage.getItem("staffInfo") ?? "");

  return (
    <Container>
      <LeftContainer>
        <LeftHeader>{title}</LeftHeader>
      </LeftContainer>
      <RightContainer>
        <UserContainer>
          <ImageContainer>
            {localInfo?.user?.displayName.slice(0, 1)}
          </ImageContainer>
          <Username>{localInfo?.user?.displayName}</Username>
        </UserContainer>
      </RightContainer>
    </Container>
  );
};

export default Header;
