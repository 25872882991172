import toast from "react-hot-toast";

export const handleLogout = async (
  logout: any,
  navigate: any,
  setShowModal?: any
) => {
  try {
    const response = await logout().unwrap();
    if (response?.responseCode === "00") {
      localStorage.clear();
      setShowModal && setShowModal(false);
      toast.success(response?.responseDescription);
      navigate("/login");
    }
  } catch (error: any) {
    console.log(error);
  }
};
