// import { createApi, fetchBaseQuery } from "@rtk-incubator/rtk-query/dist";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { baseQuery, baseQueryWithAuth, getHeaders } from ".";

const BASE_URL = process.env.REACT_APP_BASEURL;

// interface Res {
//  responseCode: string;
//  responseDescription: string;
//  data: Login | {}
// }

interface Login {
  accessToken: string;
  expiresIn: number;
  tokenType: string;
  user: {
    managerDepartment: string;
    managerName: string;
    staffName: string;
    displayName: string;
    email: string;
    department: string;
    groups: string;
    mobileNo: string;
    staffId: string;
    role: string;
    response: string;
    responseMessage: string;
  };
}
interface DataResponse {
  data: Login | {};
  error?: string;
  responseCode: string;
  responseDescription: string;
}

interface AuthResponse {
  data?: DataResponse | {};
  error?: string;
  responseCode?: string;
  responseDescription: string;
}

// const customBaseQuery: BaseQueryFn<
//   string | FetchArgs,
//   unknown,
//   FetchBaseQueryError
// > = async (args, api, extraOptions) => {
//   const baseResult = await fetchBaseQuery({
//     baseUrl: BASE_URL,
//     prepareHeaders: (_headers) => {
//       getHeaders(_headers);
//     },
//   })(args, api, extraOptions);

//   const newResponse: any = {
//     ...baseResult,
//   };
//   const errorCode = newResponse?.data?.statusCode;
//   if (errorCode === 401) {
//     localStorage.clear();
//     window.location.href = "/login";
//   }
//   return baseResult;
// };

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: baseQueryWithAuth(baseQuery),

  endpoints: (builder) => ({
    //login
    login: builder.mutation<
      AuthResponse,
      { username: string; passwordInput: string; token: string }
    >({
      query: (body) => ({
        url: "/Security",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          password: body.passwordInput, // Set the password in the header
        },
        body,
      }),
    }),
    // get reset token
    getToken: builder.mutation<any, { username: string }>({
      query: ({ username }) => ({
        url: `/Security/generate-reset-token?username=${username}`,
        method: "GET",
      }),
    }),

    //login
    resetUser: builder.mutation<any, { username: string; token: string }>({
      query: (body) => ({
        url: "/Security/reset-user",
        method: "POST",
        body,
      }),
    }),

    //logout all
    logoutAll: builder.mutation<any, { username: string }>({
      query: ({ username }) => ({
        url: `/Security/logout-all?username=${username}`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useGetTokenMutation,
  useResetUserMutation,
  useLogoutAllMutation,
} = authApi;
