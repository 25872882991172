import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { RootState } from "src/store/store";
import { generateHeader } from "src/utils/generateHeader";

const BASE_URL = process.env.REACT_APP_BASEURL;

export type BaseQueryType = ReturnType<typeof fetchBaseQuery>;

export const getHeaders = (_headers: Headers) => {
  _headers.set("X-Frame-Options", "DENY");
  _headers.set("X-Content-Type-Options", "nosniff");
  _headers.set("Cross-Origin-Opener-Policy", "same-origin");
  _headers.set(
    "Content-Security-Policy",
    "frame-ancestors 'self' X-Frame-Options: DENY"
  );

  const customerHeaders = generateHeader();
  // Customer headers
  _headers.set("x-token", customerHeaders["x-token"]);
  _headers.set(
    "Ocp-Apim-Subscription-Key",
    customerHeaders["Ocp-Apim-Subscription-Key"]!
  );
  _headers.set("Ocp-Apim-Trace", "true");
  _headers.set("UTCTimestamp", customerHeaders.UTCTimestamp);
  _headers.set("Client_ID", customerHeaders.Client_ID!);

  return _headers;
};

export const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).StaffDataReducer?.staffInfo
      ?.accessToken;

    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }

    // former headers
    headers.set("Content-Type", "application/json");
    headers.set("accept", "text/plain");
    headers.set("Access-Control-Expose-Headers", "access-token");
    headers.set("Access-Control-Allow-Origin", "*");
    headers.set("Access-Control-Allow-Methods", "*");

    headers.set("X-Frame-Options", "DENY");
    headers.set("X-Content-Type-Options", "nosniff");
    headers.set("Cross-Origin-Opener-Policy", "same-origin");
    headers.set(
      "Content-Security-Policy",
      "frame-ancestors 'self' X-Frame-Options: DENY"
    );

    const _headers = generateHeader();
    // Customer headers
    headers.set("x-token", _headers["x-token"]);
    headers.set(
      "Ocp-Apim-Subscription-Key",
      _headers["Ocp-Apim-Subscription-Key"]!
    );
    headers.set("Ocp-Apim-Trace", "true");
    headers.set("UTCTimestamp", _headers.UTCTimestamp);
    headers.set("Client_ID", _headers.Client_ID!);

    return headers;
  },
});

export const baseQueryWithAuth: (baseQuery: BaseQueryType) => BaseQueryType =
  (baseQuery) => async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    if (!navigator.onLine) {
      throw new Error("Please check your Internet Connection");
    }

    if (result.error && result.error.status === 401) {
      window.location.replace("/login");
    }

    return result;
  };
