import {
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError,
  MutationDefinition,
} from "@reduxjs/toolkit/query/react";
import { getHeaders } from ".";

const BASE_URL = process.env.REACT_APP_BASEURL;

interface UploadFileMutation {
  file: {};
}
interface filesData {
  id: number;
  debitAccount: string;
  debitAccountName: string;
  beneficiaryAccount: string;
  beneficiaryInstitutionCode: string;
  beneficiaryAccountName: string;
  amount: number;
  batchId: string;
  valueDate: string;
  createdOn: string;
  lastModified: string;
}

export const fileUploadApi = createApi({
  reducerPath: "fileUploadApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (_headers, { getState }) => {
      const headers = getHeaders(_headers);

      headers.set("Content-Type", "multipart/form-data");
      headers.set("accept", "*/*");
    },
  }),

  endpoints: (builder) => ({
    fileUpload: builder.mutation<filesData[], UploadFileMutation>({
      query: (file) => ({
        url: "/Transactions",
        method: "POST",
        body: file,
      }),
    }),
  }),
});

export const { useFileUploadMutation } = fileUploadApi;
