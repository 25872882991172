import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";
import { errorFormatter } from "src/utils/errorFormatter";

// const localInfo = localStorage.getItem("staffInfo");
// const tokenValue = JSON.parse(localInfo || "{}")?.accessToken;

// const headers = {
//   Authorization: `Bearer ${tokenValue}`,
// };

const BASE_URL = process.env.REACT_APP_BASEURL;
const END_POINT = `/Transactions/Initiated`;

export const getInitiatedTransactions = createAsyncThunk<any, any>(
  "data/getTransactions",
  async (token) => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await axios.get(`${BASE_URL}${END_POINT}`, { headers });

      return response.data;
    } catch (error: any) {
      toast.error(error?.response?.data?.data);
      return [];
    }
  }
);

// slice to get initiatedTransactions
const getInitiatedTransactionsDetails = createSlice({
  name: "initiated",
  initialState: {
    loading: false,
    error: null,
    data: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInitiatedTransactions.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.data = null;
      })
      .addCase(getInitiatedTransactions.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.data = action.payload.data;
      })
      .addCase(getInitiatedTransactions.rejected, (state, action) => {
        let message = errorFormatter(action.payload);
        state.loading = false;
        state.error = null;
        console.log(message);
      });
  },
});

export default getInitiatedTransactionsDetails.reducer;
