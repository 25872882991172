import { createAsyncThunk } from "@reduxjs/toolkit";
import { auth } from "src/services/auth";

export const loginData = createAsyncThunk<any, { username:string; token:string , passwordInput: string  }>(
    'post/addData',
    async (post, { rejectWithValue }) => {

        // the auth is a an object that contains the API call
    return auth
    .loginUser(post)
    .then((res) => res.data?.data)
    .catch((error) => {
        return rejectWithValue(error)
        
    })
    }
  )