import React, { useEffect, useState } from "react";
import "./App.css";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";
import AppRouter from "./routes/appRouter";
import { saveStaffInfo } from "./store/slices/authSlice";
import { store } from "./store/store";
import { Toaster } from "react-hot-toast";
import GeneralModal from "./components/StatusModal/GeneralModal";
import InactiveContent from "./components/InactiveContent";
import { handleLogout } from "./utils/logout";
import { useLogoutMutation } from "./services/standingService";

function App() {
  const staffInfoJSON = localStorage.getItem("staffInfo");
  const staffInfo = staffInfoJSON ? JSON.parse(staffInfoJSON) : {};
  const [showModal, setShowModal] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(60);
  const navigate = useNavigate();
  const [logout] = useLogoutMutation();

  if (staffInfo) {
    store.dispatch(saveStaffInfo(staffInfo));
  }

  const handleCancel = () => {
    setShowModal(false);
  };

  const onIdle = () => {
    if (staffInfo?.user) {
      setShowModal(true);
    }
  };

  const sessionTime = process.env.REACT_APP_SESSION_TIME;

  useIdleTimer({
    onIdle,
    timeout: parseFloat(sessionTime as string) * 60 * 1000,
    throttle: 500,
  });

  useEffect(() => {
    if (showModal) {
      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    } else {
      setCounter(60);
    }

    if (counter === 0) {
      setShowModal(false);
      handleLogout(logout, navigate);
    }
  }, [showModal, counter, navigate]);

  return (
    <>
      {showModal && (
        <GeneralModal general>
          <InactiveContent
            handleCancel={handleCancel}
            counter={counter}
            setShowModal={setShowModal}
            sessionTime={sessionTime as string}
          />
        </GeneralModal>
      )}

      <AppRouter />

      <Toaster
        position="top-right"
        toastOptions={{
          className: "",
          style: {
            margin: "10px",
            padding: "10px",
            display: "inline-flex",
            fontSize: "14px",
            zIndex: 999999,
          },
          duration: 4000,
          error: {
            style: {
              background: "red",
              color: "white",
            },
            iconTheme: {
              primary: "white",
              secondary: "red",
            },
          },
          success: {
            style: {
              background: "green",
              color: "white",
            },
            iconTheme: {
              primary: "white",
              secondary: "green",
            },
          },
        }}
      />
    </>
  );
}

export default App;
