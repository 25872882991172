import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { errorFormatter } from "src/utils/errorFormatter";

const localInfo = localStorage.getItem("staffInfo");
const tokenValue = JSON.parse(localInfo || "{}")?.accessToken;

const headers = {
  Authorization: `Bearer ${tokenValue}`,
};

const BASE_URL = process.env.REACT_APP_BASEURL;
const END_POINT = `/Transactions/Approved`;
export const getApprovedTransactions = createAsyncThunk<any>(
  "data/getTransactions",
  async (payload) => {
    const response = await axios.get(`${BASE_URL}${END_POINT}`, { headers });

    return response.data;
  }
);

// slice to get initiatedTransactions
const getApprovedTransactionsDetails = createSlice({
  name: "initiated",
  initialState: {
    loading: false,
    error: null,
    data: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getApprovedTransactions.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.data = null;
      })
      .addCase(getApprovedTransactions.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.data = action.payload;
      })
      .addCase(getApprovedTransactions.rejected, (state, action) => {
        let message = errorFormatter(action.payload);
        state.loading = false;
        state.error = null;
      });
  },
});

export default getApprovedTransactionsDetails.reducer;
