import { combineReducers } from "@reduxjs/toolkit";
import openSlice from "./slices/stateSlice";
import loginDataSlice from "./slices/login/loginSlice";
import { authApi } from "src/services/authService";
import { fileUploadApi } from "src/services/fileUploadService";
import { zoneApi } from "src/services/clearingService";
import { StaffDataReducer } from "./slices/authSlice";
import { ClearingDataReducer } from "./slices/clearingSlice";
import initiateTransactionDetails from "./slices/fileupload/initiateTransaction";
import postSlice from "./slices/fileupload/fileuploadslice";
import approveTransactionDetails from "./slices/fileupload/approveTransaction";
import declineTransactionDetails from "./slices/fileupload/declineTransaction";
import batchGet from "./slices/fileupload/getBatch";
import  getDeclinedTransactions  from "./slices/fileupload/getDeclinedTransaction";
import getApprovedTransaction from "./slices/fileupload/getApprovedTransaction";
import getInitiatedTransactionsDetails from "./slices/fileupload/initiatedTransactions";
import fetchSummary from "./slices/fileupload/uploadedSummary";
import { adminApi } from "src/services/adminService";
import { amuApi } from "src/services/amuService";
import { standingApi } from "src/services/standingService";
import { chartApi } from "src/services/chartService";
export default combineReducers({
  openSlice,
  loginDataSlice,
  postSlice,
  initiateTransactionDetails,
  approveTransactionDetails,
  declineTransactionDetails,
  getDeclinedTransactions,
  getApprovedTransaction,
  batchGet,
  fetchSummary,
  getInitiatedTransactionsDetails,
  [authApi.reducerPath]: authApi.reducer,
  [fileUploadApi.reducerPath]: fileUploadApi.reducer,
  [zoneApi.reducerPath]: zoneApi.reducer,
  [chartApi.reducerPath] : chartApi.reducer,
  [adminApi.reducerPath]: adminApi.reducer,
  [amuApi.reducerPath]: amuApi.reducer,
  [standingApi.reducerPath]: standingApi.reducer,
  StaffDataReducer: StaffDataReducer,
  ClearingDataReducer: ClearingDataReducer,
});
