import React from "react";

import PrimaryButton from "../Buttons/Button";
import { BtnHolder, Container, Content, ModalTexts, BodyText } from "./style";
import { handleLogout } from "../../utils/logout";
import { useNavigate } from "react-router-dom";
import { useLogoutMutation } from "../../services/standingService";

interface InactiveProps {
  handleCancel: () => void;
  counter: number;
  setShowModal: any;
  sessionTime: string;
}
const InactiveContent: React.FC<InactiveProps> = ({
  handleCancel,
  counter,
  setShowModal,
  sessionTime,
}) => {
  const [logout] = useLogoutMutation();
  const navigate = useNavigate();
  return (
    <Container>
      <Content>
        <ModalTexts>You have been idle for {sessionTime}mins!</ModalTexts>
        <BodyText>
          You will be logged out your session in {counter} sec. Press cancel to
          stay ?
        </BodyText>
      </Content>
      <BtnHolder>
        <PrimaryButton
          background={"#ffffff"}
          color={"#5c2684"}
          width={150}
          title={"Cancel"}
          icon={""}
          loading={false}
          leftIconName={<></>}
          rightIconName={<></>}
          onClick={handleCancel}
          leftIcon={false}
          border={"solid 1px #5c2684"}
        />
        <PrimaryButton
          background={"#5c2684"}
          color={"#fff"}
          width={150}
          title={"Logout"}
          icon={""}
          loading={false}
          leftIconName={<></>}
          rightIconName={<></>}
          onClick={() => handleLogout(logout, navigate, setShowModal)}
          leftIcon={false}
          border={""}
        />
      </BtnHolder>
    </Container>
  );
};

export default InactiveContent;
