import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";
import { errorFormatter } from "src/utils/errorFormatter";

const BASE_URL = process.env.REACT_APP_BASEURL;
const END_POINT = `/Transactions/Decline`;

const localInfo = localStorage.getItem("staffInfo");
const tokenValue = JSON.parse(localInfo || "{}")?.accessToken;

const headers = {
  Authorization: `Bearer ${tokenValue}`,
};

export const declineTransaction = createAsyncThunk<any, any>(
  "data/postData",
  async (payload) => {
    const headers = {
      Authorization: `Bearer ${payload?.token}`,
    };
    const requiredData = {
      batchId: payload?.batchId,
      transactionIds: payload?.transactionIds,
      reason: payload?.reason,
    };

    try {
      const response: any = await axios.post(
        `${BASE_URL}${END_POINT}`,
        requiredData,
        { headers }
      );
      toast.success(response?.data?.responseDescription);
      return response.data;
    } catch (err: any) {
      if (err?.responseDescription === null || undefined) {
        toast.error(err.errors.TransactionIds[0]);
      } else {
        toast.error(err?.response?.data.responseDescription);
      }
    }
  }
);

const declineTransactionDetails = createSlice({
  name: "initiate",
  initialState: {
    isLoading: false,
    error: null,
    data: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(declineTransaction.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.data = null;
      })
      .addCase(declineTransaction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.data = action.payload;
      })
      .addCase(declineTransaction.rejected, (state, action) => {
        const message = errorFormatter(action.payload);
        state.isLoading = false;
        state.error = null;
        console.log(message);
      });
  },
});

export default declineTransactionDetails.reducer;
