import styled from "styled-components";
interface statusProp {
  color?: string;
  backgroundColor?: string;
}
export const Container = styled.div`
  background-color: white;
  height: 100%;
  padding: 20px;
  border-radius: 20px;
`;
export const ButtonWrap = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  margin-top: 40px;
  justify-content: space-between;
`;
export const Title = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  font-weight: bold;
  margin-top: 10px;
  padding-left: 15px;
`;
export const HeaderText = styled.h3`
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  font-weight: bold;
  margin-top: 24px;
`;
export const DateWrap = styled.div`
  width: 100%;
  display: grid;
  margin-top: 24px;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;
export const TopContainer = styled.div`
  display: flex;
  flex-flow: column;
`;
export const BottomContainer = styled.div`
  display: flex;
  flex-flow: column;
  padding-inline: 24px;
`;
export const TableContainer = styled.div`
  display: flex;
  flex-flow: column;
  padding-inline: 24px;
`;
export const NameContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;
export const Name = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #13071c;
  background: #fafafa;
  border-radius: 8px;
  padding: 14px 21px;
`;
export const Label = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #13071c;
`;
export const Status = styled.div<statusProp>`
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  text-align: center;
  border-radius: 12px;
  padding: 4px 8px;
`;

export const LeftD = styled.div`
  width: 45%;
  padding: 15px 24px;
`;

export const RightD = styled.div`
  width: 55%;
  border-left: 0.888112px solid #dfe2e6;
  padding: 15px 24px;
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 15.986px;
  line-height: 120%;
  color: #2d2235;
`;

export const DetailsWrap = styled.div`
  width: 700px;
  background: #fafbfb;
  border: 0.888112px solid #dfe2e6;
  border-radius: 0px;
  display: flex;
`;
export const TopTitle = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  color: #2d2235;
`;
export const IconWrap = styled.div`
  cursor: pointer;
  display: flex;
  gap: 10px;
  flex-direction: row;
`;
export const FieldWrapD = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
`;
export const Top = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`;
