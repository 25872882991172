import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";
import { errorFormatter } from "src/utils/errorFormatter";

const localInfo = localStorage.getItem("staffInfo");
const tokenValue = JSON.parse(localInfo || "{}")?.accessToken;

const BASE_URL = process.env.REACT_APP_BASEURL;
const END_POINT = `/Transactions`;

export const postData = createAsyncThunk<any, any>(
  "data/postData",
  async (payload) => {
    const headers = {
      Authorization: `Bearer ${payload.token}`,
    };
    try {
      const response = await axios.post(
        `${BASE_URL}${END_POINT}`,
        payload.file,
        {
          headers,
        }
      );
      toast.success(response.data?.responseDescription);
      payload.setLoading(false);
      payload.setIsCompleted(true);
      return response.data;
    } catch (error: any) {
      payload.setLoading(false);
      toast.error(error?.response.data?.responseDescription);
    }
  }
);

type InitialStateType = {
  isLoading: boolean;
  error: string | null;
  data: null | any[];
};

const initialState: InitialStateType = {
  isLoading: false,
  error: null,
  data: null,
};

// Create a slice to store the response data from posting data
const postSlice = createSlice({
  name: "post",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.data = null;
      })
      .addCase(postData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.data = action.payload.data;
      })
      .addCase(postData.rejected, (state, action) => {
        const message = errorFormatter(action.payload);
        state.isLoading = false;
        //state.error = action.errors.file;
      });
    console.log(postData);
  },
});

export default postSlice.reducer;
