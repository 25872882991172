import { useNavigate } from "react-router-dom";
import { useGetAllRolesQuery } from "src/services/adminService";

interface ProtectedProps {
  isVerified: any;
  children: any;
}

const Protected = ({ isVerified, children }: ProtectedProps) => {
  const navigate = useNavigate();
  const allRoles = useGetAllRolesQuery();

  const itemList = allRoles?.data?.map((item: any) => item.roleName);

  const findRole = itemList?.includes(isVerified?.user?.roleName);

  if (isVerified?.accessToken) {
    return children;
  } else if (!findRole) {
    navigate("/login");
  } else {
    navigate("/login");
  }
};

export default Protected;
