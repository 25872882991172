import { configureStore } from "@reduxjs/toolkit";
import stateSlice, { openSlice } from "./slices/stateSlice";
import rootReducer from "./rootReducer";
import { devToolsEnhancer } from "@reduxjs/toolkit/dist/devtoolsExtension";
import { authApi } from "src/services/authService";
import { fileUploadApi } from "src/services/fileUploadService";
import { zoneApi } from "src/services/clearingService";
import { adminApi } from "src/services/adminService";
import { amuApi } from "src/services/amuService";
import { standingApi } from "src/services/standingService";
import { chartApi } from "src/services/chartService";
export const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat([
      authApi.middleware,
      zoneApi.middleware,
      chartApi.middleware,
      fileUploadApi.middleware,
      adminApi.middleware,
      amuApi.middleware,
      standingApi.middleware,
    ]),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
