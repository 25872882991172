import { useMemo } from "react";
import styled from "styled-components";
import {
  adminData,
  amuMakerData,
  amuSupervisorData,
  checkerData,
  clearingData,
  clearingSuperData,
  clientUserData,
  csmData,
  superUserData,
  supportTeamData,
  tbStaffData,
  tBSuperData,
} from "./data";
import SubMenu from "./style";
import { ReactComponent as FCMBIcon } from "src/assets/svg/FC.svg";
// import { authApi } from "../../services/authService";
// import { store } from "../../store/store";
import { HiOutlineLogout } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { IconContext } from "../../../node_modules/react-icons/lib/esm/iconContext";
import { SidebarProps } from "../../types/Types";
import { useSelector } from "react-redux";
import { useLogoutMutation } from "src/services/standingService";
import { store } from "src/store/store";
import { fileUploadApi } from "src/services/fileUploadService";
import { zoneApi } from "src/services/clearingService";
import { adminApi } from "src/services/adminService";
import { amuApi } from "src/services/amuService";
import { standingApi } from "src/services/standingService";
import { handleLogout } from "../../utils/logout";
import { useJwt } from "react-jwt";

const MainContainer = styled.div`
  padding: 20px;
  background-color: white;
  height: 100vh;
`;

export const LogoWrapper = styled.div`
  padding-top: 15px;
  margin-bottom: 30px;
`;

const SidebarNav = styled.nav`
  width: 280px;
  height: 85%;
  display: flex;
  flex: 1;
  scroll-behavior: smooth;
  justify-content: center;
  position: sticky;
  top: 0;
  overflow: scroll;
  transition: 350ms;
  z-index: 10;
`;

const SidebarWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SidebarParentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LogoutWrapper = styled.div`
  display: flex;
  padding: 12px;
  align-items: center;
  align-items: center;
  width: 100%;
`;

const IconButtons = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  outline: none;
  cursor: pointer;
  border: none;
`;

const ButtonTexts = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  margin-left: 5px;
  padding-top: 7px;
  color: ${(props) => props.color};
`;

const Sidebar = () => {
  const navigate = useNavigate();
  // const staffInfoJSON = localStorage.getItem("staffInfo");
  const localInfo = JSON.parse(localStorage.getItem("staffInfo") ?? "");
  const [logout] = useLogoutMutation();
  const accessToken = localInfo.accessToken;

  const { decodedToken } = useJwt(accessToken);

  var options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const BASE_URL = process.env.REACT_APP_BASEURL;

  const decodedData: any = {
    ...(decodedToken as object),
  };

  const filter = decodedData?.role;

  const memoizedIconContextValue = useMemo(
    () => ({ className: "sidebar-icons" }),
    []
  );

  // const staffInfo = useSelector(
  //   (state: any) => state.StaffDataReducer?.staffInfo
  // );

  return (
    <MainContainer>
      <IconContext.Provider value={memoizedIconContextValue}>
        <LogoWrapper>
          <FCMBIcon />
        </LogoWrapper>

        <SidebarNav>
          <SidebarWrap>
            {/* admin */}
            {filter === "Admin" && (
              <>
                {adminData.map((item: SidebarProps) => {
                  return <SubMenu item={item} key={item.title} />;
                })}
              </>
            )}

            {/* head teller */}
            {filter === "Head Teller" && (
              <>
                {checkerData.map((item: SidebarProps) => {
                  return <SubMenu item={item} key={item.title} />;
                })}
              </>
            )}

            {filter === "TB Ops Staff" && (
              <>
                {checkerData.map((item: SidebarProps) => {
                  return <SubMenu item={item} key={item.title} />;
                })}
              </>
            )}

            {/* clearing staff */}
            {filter === "Clearing" && (
              <>
                {clearingData.map((item: SidebarProps) => {
                  return <SubMenu item={item} key={item.title} />;
                })}
              </>
            )}

            {/* clearing supervisor */}
            {filter === "Clearing Supervisor" && (
              <>
                {clearingSuperData.map((item: SidebarProps) => {
                  return <SubMenu item={item} key={item.title} />;
                })}
              </>
            )}

            {/* tb staff */}
            {/* {filter === "TB Ops Staff" && (
              <>
                {tbStaffData.map((item: SidebarProps) => {
                  return <SubMenu item={item} key={item.title} />;
                })}
              </>
            )} */}

            {/* tb supervisor */}
            {/* {filter === "TB Ops Supervisor" && (
              <>
                {tBSuperData.map((item: SidebarProps) => {
                  return <SubMenu item={item} key={item.title} />;
                })}
              </>
            )} */}

            {/* csm */}
            {filter === "CSM" && (
              <>
                {csmData.map((item: SidebarProps) => {
                  return <SubMenu item={item} key={item.title} />;
                })}
              </>
            )}

            {filter === "TB Ops Supervisor" && (
              <>
                {csmData.map((item: SidebarProps) => {
                  return <SubMenu item={item} key={item.title} />;
                })}
              </>
            )}

            {/* support */}
            {filter === "Support Team" && (
              <>
                {supportTeamData.map((item: SidebarProps) => {
                  return <SubMenu item={item} key={item.title} />;
                })}
              </>
            )}

            {/* amu maker */}
            {filter === "AMU Maker" && (
              <>
                {amuMakerData.map((item: SidebarProps) => {
                  return <SubMenu item={item} key={item.title} />;
                })}
              </>
            )}

            {/* amu supervisor */}
            {filter === "AMU Supervisor" && (
              <>
                {amuSupervisorData.map((item: SidebarProps) => {
                  return <SubMenu item={item} key={item.title} />;
                })}
              </>
            )}

            {/* client user */}
            {filter === "ClientUser" && (
              <>
                {clientUserData.map((item: SidebarProps) => {
                  return <SubMenu item={item} key={item.title} />;
                })}
              </>
            )}

            {/* super user */}
            {filter === "SuperUser" && (
              <>
                {superUserData.map((item: SidebarProps) => {
                  return <SubMenu item={item} key={item.title} />;
                })}
              </>
            )}

            <SidebarParentWrapper>
              <LogoutWrapper onClick={() => handleLogout(logout, navigate)}>
                <IconButtons>
                  <HiOutlineLogout color={"#464347"} size={20} />
                  <ButtonTexts color={"#464347"}>Logout</ButtonTexts>
                </IconButtons>
              </LogoutWrapper>
            </SidebarParentWrapper>
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>
    </MainContainer>
  );
};

export default Sidebar;
