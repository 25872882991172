import styled from "styled-components";

interface statusProp {
  color?: string;
  backgroundColor?: string;
}

export const Container = styled.div`
  border-radius: 20px;
  background: #fff;
  padding: 30px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 30px;
  margin-bottom: 10rem;
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 24px;
  color: #2d2235;
`;

export const LeftD = styled.div`
  width: 45%;
  padding: 8px 8px;
`;

export const RightD = styled.div`
  width: 55%;
  border-left: 0.888112px solid #dfe2e6;
  padding: 8px 8px;
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 10px;
  line-height: 120%;
  color: #2d2235;
`;

export const IconWrap = styled.div`
  cursor: pointer;
  display: flex;
  gap: 10px;
  flex-direction: row;
`;

export const FieldWrapD = styled.div`
  margin-bottom: 5px;
  display: flex;
  padding-bottom: 1rem;
  flex-direction: column;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 1.6rem;
`;
export const DateWrap = styled.div`
  display: flex;
  gap: 2rem;
  margin-top: -3rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
`;
export const DetailsWrap = styled.div`
  width: 500px;
  background: #fafbfb;
  border: 0.888112px solid #dfe2e6;
  border-radius: 0px;
  display: flex;
`;
export const TopTitle = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  color: #2d2235;
`;
export const Top = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
export const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  width: 100%;
  padding-bottom: 1rem;
`;
export const MiniBtnWrap = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const Status = styled.div<statusProp>`
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  text-align: center;
  border-radius: 12px;
  padding: 4px 8px;
`;
