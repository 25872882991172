import Paginator from "../../components/Paginator";
import React, { useEffect, useMemo, useState } from "react";
import {
  TableBody,
  TableBodyData,
  TableBodyRow,
  TableContainer,
  TableHeader,
  TableHeaderRow,
  TableHeaderTitle,
  TablePaginatorWrapper,
  TableWrapper,
} from "./style";

export interface TableCProps {
  header: string[];
  body: any[][] | undefined;
  arrOfObject: boolean;
}

const PrimaryTable: React.FC<TableCProps> = ({ header, body, arrOfObject }) => {
  const [currentItems, setCurrentItems] = useState<any[][] | undefined>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;

  const dataBody = useMemo(() => {
    return body ?? [];
  }, [body]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(dataBody?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(dataBody?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, dataBody]);

  const handlePageClick = (e: any) => {
    const newOffset = (e.selected * itemsPerPage) % dataBody?.length;
    setItemOffset(newOffset);
  };

  return (
    <TableContainer>
      <TableWrapper>
        <TableHeader>
          <TableHeaderRow>
            {header?.map((title, index) => (
              <TableHeaderTitle key={index}>{title}</TableHeaderTitle>
            ))}
          </TableHeaderRow>
        </TableHeader>

        {arrOfObject ? (
          <TableBody>
            {currentItems?.map((text, index) => (
              <TableBodyRow key={index}>
                {Object.values(text).map((value, index) => (
                  <TableBodyData key={index}>{value}</TableBodyData>
                ))}
              </TableBodyRow>
            ))}
          </TableBody>
        ) : (
          <TableBody>
            {currentItems?.map((data, index) => (
              <TableBodyRow key={index}>
                {/* {data?.map((text, index) => (
                  <TableBodyData key={index}>{text}</TableBodyData>
                ))} */}
              </TableBodyRow>
            ))}
          </TableBody>
        )}
      </TableWrapper>

      {dataBody?.length > 10 && (
        <TablePaginatorWrapper>
          <Paginator handlePageClick={handlePageClick} pageCount={pageCount} />
        </TablePaginatorWrapper>
      )}
    </TableContainer>
  );
};

export default PrimaryTable;
