// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  /* padding: 0; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  background: whitesmoke;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Poppins", sans-serif;
}

/* @font-face {
  font-family: Roboto;
  src: local("Roboto"), url(./fonts/Roboto/RobotoBook.ttf) format("truetype");
}
@font-face {
  font-family: "RobotoBold";
  src: local("RobotoBold"),
    url(./fonts/Roboto/RobotoBold.ttf) format("truetype");
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AACA;EACE,SAAS;EACT,gBAAgB;EAChB;;cAEY;EACZ,mCAAmC;EACnC,sBAAsB;EACtB,kCAAkC;EAClC,kCAAkC;AACpC;;AAEA;;;;;;;;GAQG;;AAEH;EACE;aACW;AACb","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Poppins&display=swap\");\nbody {\n  margin: 0;\n  /* padding: 0; */\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  background: whitesmoke;\n  -moz-osx-font-smoothing: grayscale;\n  font-family: \"Poppins\", sans-serif;\n}\n\n/* @font-face {\n  font-family: Roboto;\n  src: local(\"Roboto\"), url(./fonts/Roboto/RobotoBook.ttf) format(\"truetype\");\n}\n@font-face {\n  font-family: \"RobotoBold\";\n  src: local(\"RobotoBold\"),\n    url(./fonts/Roboto/RobotoBold.ttf) format(\"truetype\");\n} */\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
