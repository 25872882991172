import { createSlice, PayloadAction , createAsyncThunk } from '@reduxjs/toolkit';
import { loginData } from './loginAsyncThunk';
import { errorFormatter } from 'src/utils/errorFormatter';
import { setUserToken } from 'src/services/storage';
interface Login {

  accessToken: string;
  expiresIn : number;
  tokenType : string;
  user: {
    managerDepartment: string;
    managerName:string;
    staffName: string;
    displayName: string;
    email: string;
    department:string;
    groups: string;
    mobileNo: string;
    staffId: string;
    response: string;
    responseMessage: string;
  }
}

interface LoginState {
  data: Login | null;
  loading: boolean;
  error: string | null;
}

const initialState: LoginState = {
  data: null,
  loading: false,
  error: null,
};

// login slice that handles the states of the data posted into the API endpoints.
const loginDataSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    
    builders.addCase(loginData.pending, (state) => {
       state.loading = true;
    })

    builders.addCase(loginData.fulfilled, (state, action) => {
      
      state.loading = false;
      state.data = action.payload

      // sets user token to localStorage , so would be available even on refresh
      setUserToken(action.payload.accessToken)
    })

    builders.addCase(loginData.rejected , (state , action) => {
      const message = errorFormatter(action.payload)  
      state.loading = false;
        state.error = message
    })

  }
});

export default loginDataSlice.reducer;
