import styled from "styled-components";


interface statusProp {
    color?: string;
    backgroundColor?: string;
  }


export const Container = styled.div`
  border-radius: 20px;
  background: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
export const ActionDiv = styled.div`
  margin-left: 15%;
  //padding: 1%;

`
export const TextErr = styled.h6`
  width: 1rem;
  padding: 2rem;
`
export const ButtonContain = styled.div`
  display: flex;
  position: relative;
  padding: 30px;
  gap: 10px;
  justify-content: flex-end;
`;
export const Status = styled.div<statusProp>`
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  text-align: center;
  border-radius: 12px;
  padding: 4px 8px;
`;
export const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`;
export const DateWrap = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;
export const HeaderText = styled.h3`
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  font-weight: bold;
  margin-top: 24px;
`;
