import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "src/store/store";
import { baseQuery, baseQueryWithAuth, getHeaders } from ".";

const BASE_URL = process.env.REACT_APP_BASEURL;

interface Data {
  isOpen: boolean;
}
interface ZoneResponse {
  responseCode: string;
  responseDescription: string;
  status: boolean;
  data: {
    isOpen: boolean;
  };
}

interface ZoneList {
  closeTime: string | undefined;
  id: number;
  isActive: boolean;
  isOpen: boolean;
  openTime: string | undefined;
  zoneName: string;
}

interface postZone {
  zoneName: string;
  isOpen: boolean;
}

interface postPropZone {
  body: postZone | undefined;
  openTime: string | undefined;
  closeTime: string | undefined;
}
interface postZoneResponseData {
  data?: string;
  responseCode?: string;
  responseDescription?: null;
  status?: boolean;
}
// const customBaseQuery: BaseQueryFn<
//   string | FetchArgs,
//   unknown,
//   FetchBaseQueryError
// > = async (args, api, extraOptions) => {
//   const baseResult = await fetchBaseQuery({
//     baseUrl: BASE_URL,
//     prepareHeaders: (_headers, { getState }) => {
//       const token = (getState() as RootState).StaffDataReducer?.staffInfo
//         ?.accessToken;

//       const headers = getHeaders(_headers);

//       headers.set("Content-Type", "application/json");
//       headers.set("accept", "text/plain");
//       headers.set("Access-Control-Expose-Headers", "access-token");
//       headers.set("Access-Control-Allow-Origin", "*");
//       headers.set("Access-Control-Allow-Methods", "*");
//       if (token) {
//         headers.set("Authorization", `Bearer ${token}`);
//       }
//     },
//   })(args, api, extraOptions);
//   const newResponse: any = {
//     ...baseResult,
//   };
//   const errorCode = newResponse?.data?.statusCode;

//   if (errorCode === 401) {
//     localStorage.clear();
//     window.location.href = "/login";
//   }
//   return baseResult;
// };

export const zoneApi = createApi({
  reducerPath: "zoneApi",
  baseQuery: baseQueryWithAuth(baseQuery),

  endpoints: (builder) => ({
    //get zone status
    getZoneStatus: builder.query<any, void>({
      query: (token) => ({
        url: "/Zones/status",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      }),
      transformResponse: (response: { data: any }, meta, arg) => response.data,
    }),

    //get zone status
    getZone: builder.query<ZoneList[], void>({
      query: (token) => ({
        url: "/Zones",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      }),
      transformResponse: (response: { data: ZoneList[] }, meta, arg) =>
        response.data,
    }),

    //post zone
    postZone: builder.mutation<postZoneResponseData, Partial<postPropZone>>({
      query: ({ openTime, closeTime, body }) => ({
        url: `/Zones?openTime=${openTime}&closeTime=${closeTime}`,
        method: "POST",
        body,
      }),
    }),

    //xml files
    xmlFiles: builder.mutation<
      any,
      { startDate: string; endDate: string; batchId: string; token: string }
    >({
      query: ({ startDate, endDate, batchId, token }) => ({
        url: `Clearing/xml-files?StartDate=${startDate}&EndDate=${endDate}&BatchId=${batchId}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    //xml download
    xmlDownload: builder.mutation<any, { batchId: string; token: string }>({
      query: ({ batchId, token }) => ({
        url: `/Clearing/download-xml/${batchId}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "api-supported-versions": "1.0",
          "cache-control": "no-cache",
          "content-disposition": `attachment; filename=2401250637039529.zip; filename*=UTF-8''2401250637039529.zip`,
          "content-encoding": "gzip",
          "content-type": "application/xml; x-api-version=1",
          "transfer-encoding": "chunked",
          vary: "Accept-Encoding",
          "x-frame-options": "DENY,DENY",
        },
      }),
    }),

    getBulkPending: builder.mutation<any, void>({
      query: (token) => ({
        url: `/Transactions/Bulk-Pending`,

        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    initiateTransaction: builder.mutation<
      any,
      { batchId: string; transactionIds: string[]; token: string }
    >({
      query: (body) => ({
        url: `/Transactions/Initiate`,
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetZoneStatusQuery,
  useGetZoneQuery,
  usePostZoneMutation,
  useXmlFilesMutation,
  useXmlDownloadMutation,
  useGetBulkPendingMutation,
  useInitiateTransactionMutation,
} = zoneApi;
