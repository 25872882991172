

const STORAGE_KEYS = {
    CLIENT_TOKEN_STORAGE_KEY : 'user.token'
}

export const getToken = ( key : string) => {
    return localStorage.getItem(key) || null
}

export const setToken = (key:string , value : any) => {
    return localStorage.setItem(key , value)
}

export const removeToken = ( key : string) => {
    localStorage.removeItem(key);
    return null;
}

export const fetchUserToken = () => {
    getToken(STORAGE_KEYS.CLIENT_TOKEN_STORAGE_KEY);
}
export const setUserToken = ( token : string) => {
    setToken(STORAGE_KEYS.CLIENT_TOKEN_STORAGE_KEY , token );
}

export const clearUserToken = () => {
    removeToken(STORAGE_KEYS.CLIENT_TOKEN_STORAGE_KEY)
}