import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { errorFormatter } from "src/utils/errorFormatter";

const localInfo = localStorage.getItem("staffInfo");
const tokenValue = JSON.parse(localInfo || "{}")?.accessToken;

const headers = {
  Authorization: `Bearer ${tokenValue}`,
};
const BASE_URL = process.env.REACT_APP_BASEURL;
const END_POINT = `/Transactions/Batch`;

export const retrieveBatch = createAsyncThunk<any, any>(
  "data/getData",
  async (payload) => {
    const headers = {
      Authorization: `Bearer ${payload?.token}`,
    };

    const response = await axios.get(
      `${BASE_URL}${END_POINT}/${payload?.batchId}/Approved`,
      {
        headers,
      }
    );
    return response.data;
  }
);
export const retrieveAllBatch = createAsyncThunk<any, any>(
  "data/getData",
  async (payload) => {
    const headers = {
      Authorization: `Bearer ${payload?.token}`,
    };

    const response = await axios.get(
      `${BASE_URL}${END_POINT}/${payload?.batchId}?PageSize=${payload?.pageSize}`,
      {
        headers,
      }
    );
    return response.data;
  }
);

// Create a slice to store the response data from posting data
const batchGet = createSlice({
  name: "batch",
  initialState: {
    isLoading: false,
    error: null,
    data: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveBatch.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.data = null;
      })
      .addCase(retrieveBatch.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.data = action.payload;
      })
      .addCase(retrieveBatch.rejected, (state, action) => {
        const message = errorFormatter(action.payload);
        state.isLoading = false;
        state.error = null;
        console.log(message);
      });
  },
});

export default batchGet.reducer;
