import { createSlice } from "@reduxjs/toolkit";

// This slice will hold user needed information
interface Login {
  accessToken: string;
  expiresIn: number;
  tokenType: string;
  user: {
    managerDepartment: string;
    managerName: string;
    staffName: string;
    displayName: string;
    email: string;
    department: string;
    groups: string;
    mobileNo: string;
    staffId: string;
    role: string;
    response: string;
    responseMessage: string;
  };
}
interface LoginState {
  staffInfo: Login | undefined;
  openUserDrop: boolean;
  password: string;
}

const initialState: LoginState = {
  staffInfo: undefined,
  openUserDrop: false,
  password: "",
};

const StaffData = createSlice({
  name: "User data",
  initialState,
  reducers: {
    saveStaffInfo: (state, action) => {
      state.staffInfo = action.payload;
    },
    setOpenUserDrop: (state, action) => {
      state.openUserDrop = action.payload;
    },
    savePassword: (state, action) => {
      state.password = action.payload;
    },
  },
});
export const StaffDataReducer = StaffData.reducer;

export const { saveStaffInfo, setOpenUserDrop, savePassword } =
  StaffData.actions;
