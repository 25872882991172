import axios from "axios";
import { fetchUserToken } from "./storage";

const headers = {
  "Content-Type": " application/json-patch+json",
  Accept: "application/json-patch+json",
};

const setAuthorization = () => ({
  Authorization: `Bearer ${fetchUserToken()}`,
});

// an instance that would be used for subsequent API calls,
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  // timeout: 5000,
  headers,
});
// this request are for those not sending any token
export const makeUnAuthorizedRequest = async (
  method: string,
  url: string,
  data: any
) => {
  const response = await axiosInstance.request({
    method,
    url,
    data,
    headers: { ...headers, password: data?.passwordInput || null },
  });

  return response;
};
// this are for the requests that sends a token
export const makeAuthorizedRequestWithHeadersAndPayload = async (
  method: string,
  url: string,
  passwordInput: string,
  data: any
) => {
  const response = await axiosInstance.request({
    method,
    url,
    data,
    headers: {
      ...headers,
      passwordInput,
      ...setAuthorization(),
    },
  });
  return response;
};
