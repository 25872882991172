import { useEffect, useState } from "react";
import ContentLayout from "src/layout/Content";
import Header from "../../../components/Header/Header";
import {
  Container,
  HeaderText,
  BottomContainer,
  TableContainer,
} from "./style";

import {
  useApproveUserMutation,
  usePendingUserApprovalMutation,
} from "src/services/adminService";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import PrimaryTable from "../../../components/Table";
import PrimaryButton from "../../../components/Buttons/Button";
import PuffLoader from "../../../components/Loader";

const UserApproval = () => {
  const token = useSelector(
    (state: any) => state.StaffDataReducer?.staffInfo?.accessToken
  );
  const [pendingList, setpendingList] = useState<any>([]);
  const [username, setUsername] = useState<string>("");
  const [pendingUser, pendingUserMutation] = usePendingUserApprovalMutation();
  const [approveUser, approveUserMutation] = useApproveUserMutation();

  const handleGetPendingList = async () => {
    try {
      const response = await pendingUser().unwrap();

      if (response.responseCode === "00") {
        toast.success(response?.responseDescription);
        setpendingList(response?.data);
      }
    } catch (err: any) {
      toast.error(err?.data?.responseDescription);
    }
  };
  useEffect(() => {
    handleGetPendingList();
  }, []);

  const handleAction = async (username: string) => {
    setUsername(username);
  };

  const handleApprove = async () => {
    try {
      const requiredData = {
        username: username,
        isApproved: true,
      };

      const response = await approveUser(requiredData).unwrap();
      if (response.responseCode === "00") {
        toast.success(response?.responseDescription);
        handleGetPendingList();
      }
    } catch (error: any) {
      toast.error(error?.data?.responseDescription);
    }
  };

  const handleDecline = async () => {
    try {
      const requiredData = {
        username: username,
        isApproved: false,
      };
      const response = await approveUser(requiredData).unwrap();

      if (response.responseCode === "00") {
        toast.success(response?.responseDescription);
        handleGetPendingList();
      }
    } catch (error: any) {
      toast.error(error?.data?.responseDescription);
    }
  };

  const userActionList = [
    {
      title: "Approve",
      action: handleApprove,
    },
    {
      title: "Decline",
      action: handleDecline,
    },
  ];

  const header = [
    "S/N",
    "UserName",
    "Role Name",
    "Created By",
    "Created On",
    "Last Seen",
    "Action",
  ];

  const dataBody = pendingList?.map((item: any, index: number) => [
    index + 1,
    item?.username,
    item?.rolename,
    item?.createdby,
    item?.CreatedOn?.slice(0, 10),
    item?.lastlogin?.slice(0, 10),
    <>
      <PrimaryButton
        key={item.username}
        background={"#fff"}
        color={"#5c2684"}
        width={"100%"}
        title={"Action"}
        icon={""}
        rightIcon={true}
        act={true}
        loading={false}
        leftIconName={<></>}
        rightIconName={<></>}
        onClick={() => handleAction(item?.username)}
        leftIcon={false}
        border={"1px solid #5c2684"}
        action={true}
        options={userActionList}
        item={item}
      />
    </>,
  ]);

  return (
    <>
      <Header title="Profile Management" />
      <ContentLayout>
        <Container>
          <BottomContainer>
            <HeaderText>Users</HeaderText>
          </BottomContainer>

          <TableContainer>
            {pendingUserMutation?.isLoading ? (
              <PuffLoader />
            ) : (
              <PrimaryTable
                header={header}
                body={dataBody}
                arrOfObject={true}
              />
            )}
          </TableContainer>
        </Container>
      </ContentLayout>
    </>
  );
};

export default UserApproval;
