import styled from "styled-components";

interface ButtonWrapperProps {
  background: string;
  color: string;
  width: string | number;
  height?: string | number;
  border: string;
  paddingTop?: string | number
}

export const ButtonContainer = styled.div<ButtonWrapperProps>`
  width: ${(props) => props.width};
`;

export const ButtonWrapper = styled.button<ButtonWrapperProps>`
  background: ${(props) => (props.background ? props.background : "#5C2684")};
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  padding-top: ${(props) => props.paddingTop}px;
  color: ${(props) => (props.color ? props.color : "white")};
  border-radius: 5px;
  outline: none;
  gap: 5px;
  padding: 0.7vw;
  //height: clamp(40px, 2vw, 48px);
  cursor: pointer;
  //padding-top: 0px !important;
  //padding-bottom: 15px !important;
  padding-inline: 14px;
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: center;
  border: ${(props) => (props.border ? props.border : "none")};
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: bold;
  position: relative;

  & :focus {
    border: 2px #5c2684;
  }
`;

export const IconContainer = styled.div`
  margin-left: 32px;
`;
export const LeftIconContainer = styled.div`
  /* background-color: white;
  padding-left: 3px;
  border-radius: 4px; */
`;

export const DropDown = styled.div`
  background: #fafafa;
  position: absolute;
  width: 150px;
  top: 25px;
  left: 100px;
  overflow-y: auto;
  padding: 0px !important;
  margin: 0px !important;
  z-index: 5;
`;

export const ListItems = styled.ul`
  display: flex;
  height: 100%;
  margin: 0px !important;
  flex-direction: column;
  padding: 0px !important;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
`;

export const ListItem = styled.li`
  display: flex;
  padding: 10px 24px;
  align-items: center;
  font-size: 12px;
  border: 1px solid #dfe2e6;

  &:hover {
    background-color: #5c2684;
    cursor: pointer;
    color: white;
  }
`;
export const InvisibleBackDrop = styled.div`
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
`;
