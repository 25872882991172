import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "src/store/store";
import { baseQuery, baseQueryWithAuth, getHeaders } from ".";

const BASE_URL = process.env.REACT_APP_BASEURL;

interface ChargeResponse {
  id: number;
  chargeAmount: number;
  minimumTransactionAmount: number;
  maximumTransactionAmount: number;
  fcmbIncome: number;
  nibssIncome: number;
  vat: number;
  createdOn: String;
  lastModified: Date;
  createdBy: string;
}

// interface RoleResponse {
//   responseCode: string;
//   responseDescription: string;
//   status: boolean;
//   data: RoleList[] | null;
// }
interface addResponse {
  data: string;
  error: string;
  responseCode: string;
  responseDescription: string;
}
interface updateChargeData {
  data: ChargeResponse;
  responseCode: string;
  responseDescription: string;
  error: string;
}

export interface chargeStruct {
  [key: string]: number;
  chargeAmount: number;
  minimumTransactionAmount: number;
  maximumTransactionAmount: number;
  fcmbIncome: number;
  nibssIncome: number;
  vat: number;
}

interface chargeStructRequest {
  body?: chargeStruct;
  chargeId?: number;
}

interface chargeStructResponse {
  data?: chargeStruct;
}

interface ResponseError extends Error {
  status?: number;
}
const customBaseQuery: BaseQueryFn<
  string | FetchArgs,
  any,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const baseResult = await fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (_headers, { getState }) => {
      const token = (getState() as RootState).StaffDataReducer?.staffInfo
        ?.accessToken;

      const headers = getHeaders(_headers);

      headers.set("Content-Type", "application/json");
      headers.set("accept", "text/plain");
      headers.set("Access-Control-Expose-Headers", "access-token");
      headers.set("Access-Control-Allow-Origin", "*");
      headers.set("Access-Control-Allow-Methods", "*");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
    },
  })(args, api, extraOptions);

  const newResponse: any = {
    ...baseResult,
  };

  const errorCode = newResponse?.data?.statusCode;

  if (errorCode === 401) {
    localStorage.clear();
    window.location.href = "/login";
  }
  return baseResult;
};

export const amuApi = createApi({
  reducerPath: "amuApi",
  baseQuery: baseQueryWithAuth(baseQuery),

  endpoints: (builder) => ({
    //get all charges
    getAllCharges: builder.query<ChargeResponse[], void>({
      query: (token) => "/Charges",
      transformResponse: (response: { data: ChargeResponse[] }, meta, arg) =>
        response.data,
    }),

    //get a charge
    getCharge: builder.mutation<chargeStructResponse, { id: number }>({
      query: ({ id }) => ({
        url: `/Charges/${id}`,
        method: "GET",
      }),
      //   transformResponse: (response: { data: chargeStruct }, meta, arg) =>
      //     response.data,
    }),
    //add a charge
    addCharge: builder.mutation<addResponse, Partial<chargeStruct>>({
      query: (body) => ({
        url: "/Charges",
        method: "POST",
        body,
      }),
    }),

    //update a charge
    updateCharge: builder.mutation<
      updateChargeData,
      Partial<chargeStructRequest>
    >({
      query: ({ body, chargeId }) => ({
        url: "/Charges/UpdateCharge",
        method: "POST",
        body,
        headers: {
          chargeId: `${chargeId}`,
        },
      }),
    }),
  }),
});

export const {
  useGetAllChargesQuery,
  useGetChargeMutation,
  useAddChargeMutation,
  useUpdateChargeMutation,
} = amuApi;
