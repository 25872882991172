import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "src/store/store";
import { baseQuery, baseQueryWithAuth, getHeaders } from ".";

const BASE_URL = process.env.REACT_APP_BASEURL;

type responseDataType = {
  successfulTransactions: number;
  failedTransactions: number;
  weekDay: string;
  date: string;
};

type SendingData = {
  status: string;
  date: string;
  week: string;
};

// const customBaseQuery: BaseQueryFn<
//   string | FetchArgs,
//   unknown,
//   FetchBaseQueryError
// > = async (args, api, extraOptions) => {
//   const baseResult = await fetchBaseQuery({
//     baseUrl: BASE_URL,
//     prepareHeaders: (_headers, { getState }) => {
//       const token = (getState() as RootState).StaffDataReducer?.staffInfo
//         ?.accessToken;

//       const headers = getHeaders(_headers);

//       headers.set("Content-Type", "application/json");
//       headers.set("accept", "text/plain");
//       headers.set("Access-Control-Expose-Headers", "access-token");
//       headers.set("Access-Control-Allow-Origin", "*");
//       headers.set("Access-Control-Allow-Methods", "*");
//       if (token) {
//         headers.set("Authorization", `Bearer ${token}`);
//       }
//     },
//   })(args, api, extraOptions);
//   const newResponse: any = {
//     ...baseResult,
//   };

//   const errorCode = newResponse?.data?.statusCode;
//   if (errorCode === 401) {
//     localStorage.clear();
//     window.location.href = "/login";
//   }
//   return baseResult;
// };

export const chartApi = createApi({
  reducerPath: "chartApi",
  baseQuery: baseQueryWithAuth(baseQuery),

  endpoints: (builder) => ({
    //get all charges
    getAllCharts: builder.query<responseDataType[], SendingData>({
      query: (token) => ({
        url: "/Dashboard/efficiencyRatio",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      }),
      transformResponse: (response: { data: responseDataType[] }, meta) =>
        response.data,
    }),
  }),
});

export const { useGetAllChartsQuery } = chartApi;
