// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  margin-bottom: 0px !important;
  gap: 15px;
}

.pagination .page-num {
  padding: 8px 14px;
  cursor: pointer;
  border-radius: 50px;
  font-weight: 700;
  color: #0d263b;
}

.pagination .page-num:hover {
  background-color: #f6f8f9;
  color: #0d263b;
}
.pagination .active {
  background-color: #5c2684;
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/components/Paginator/style.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,6BAA6B;EAC7B,SAAS;AACX;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,mBAAmB;EACnB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB;AACA;EACE,yBAAyB;EACzB,YAAY;AACd","sourcesContent":[".pagination {\n  list-style-type: none;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 13px;\n  margin-bottom: 0px !important;\n  gap: 15px;\n}\n\n.pagination .page-num {\n  padding: 8px 14px;\n  cursor: pointer;\n  border-radius: 50px;\n  font-weight: 700;\n  color: #0d263b;\n}\n\n.pagination .page-num:hover {\n  background-color: #f6f8f9;\n  color: #0d263b;\n}\n.pagination .active {\n  background-color: #5c2684;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
