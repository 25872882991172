import { AxiosError } from "axios";

export const errorFormatter = ( error: any) => {
    let message = "Unknown Error";

    if (error instanceof AxiosError && error.response?.data){
            message = error.response?.data?.message || "Unknown Error";
        }
        else if ( error instanceof Error){
            message = error.message
        }
        return message;
    }
