import {
  HiHome,
  HiOutlineUserGroup,
  HiOutlineGlobeAlt,
  HiTrendingUp,
  HiOutlineBadgeCheck,
  HiPencilAlt,
  HiCreditCard,
  HiArchive,
  HiCloudDownload,
  HiOutlineCalendar,
  HiClipboardList,
  HiCloudUpload,
} from "react-icons/hi";

import { AiOutlineClear } from "react-icons/ai";
import { SidebarProps } from "../../types/Types";

export const checkerData: SidebarProps[] = [
  {
    title: "Dashboard",
    path: "/",
    icon: HiHome,
  },
  {
    title: "Transaction",
    path: "/transaction",
    icon: HiPencilAlt,
    subNav: [
      {
        title: "Upload",
        path: "/transaction/upload-neft",
        icon: HiCloudUpload,
      },
      {
        title: "Bulk",
        path: "/transaction/pending-bulk",
        icon: HiCloudUpload,
      },
    ],
  },
  {
    title: "Reports",
    path: "/general-report",
    icon: HiOutlineCalendar,
  },
];

export const adminData: SidebarProps[] = [
  {
    title: "Dashboard",
    path: "/",
    icon: HiHome,
  },

  {
    title: "Charge Update",
    path: "/charge-update",
    icon: HiCloudDownload,
  },
  {
    title: "Profile Management",
    path: "/admin-profile-management",
    icon: HiOutlineUserGroup,
  },

  {
    title: "Approval",
    path: "/approval",
    icon: HiPencilAlt,
    subNav: [
      {
        title: "User Approval",
        path: "/approval/user-approval",
        icon: HiCloudUpload,
      },
      {
        title: "Charge Approval",
        path: "/approval/charge-approval",
        icon: HiCloudUpload,
      },
    ],
  },
];

export const superUserData: SidebarProps[] = [
  {
    title: "Dashboard",
    path: "/",
    icon: HiHome,
  },
  {
    title: "Profile Management",
    path: "/admin-profile-management",
    icon: HiOutlineUserGroup,
  },
];

export const clearingData: SidebarProps[] = [
  {
    title: "Dashboard",
    path: "/",
    icon: HiHome,
  },
  {
    title: "Zone",
    path: "/zone",
    icon: HiOutlineGlobeAlt,
  },
  {
    title: "Transaction",
    path: "/transaction",
    icon: HiPencilAlt,
    subNav: [
      {
        title: "Upload",
        path: "/transaction/upload-neft",
        icon: HiCloudUpload,
      },
      {
        title: "Bulk",
        path: "/transaction/pending-bulk",
        icon: HiCloudUpload,
      },
    ],
  },
  {
    title: "Reports",
    path: "/reports",
    icon: HiOutlineCalendar,
    subNav: [
      {
        title: "General Report",
        path: "/reports/general-report",
        icon: HiOutlineCalendar,
      },

      {
        title: "Branch Report",
        path: "/reports/branch-report",
        icon: HiOutlineCalendar,
      },
    ],
  },
  {
    title: "Clearing",
    path: "/clearing",
    icon: AiOutlineClear,
  },
];

export const clearingSuperData: SidebarProps[] = [
  {
    title: "Dashboard",
    path: "/",
    icon: HiHome,
  },
  {
    title: "Transactions",
    path: "/supervisor",
    icon: HiCloudUpload,
  },
  {
    title: "Branch Report",
    path: "/branch-report",
    icon: HiOutlineCalendar,
  },

  {
    title: "Outward Neft",
    path: "/outward-neft",
    icon: HiTrendingUp,
  },
  {
    title: "XML File",
    path: "/xml-file",
    icon: HiArchive,
  },
];

export const tbStaffData: SidebarProps[] = [
  {
    title: "Dashboard",
    path: "/",
    icon: HiHome,
  },
  {
    title: "Transactions",
    path: "/transaction-status",
    icon: HiCreditCard,
  },
  {
    title: "Charge Update",
    path: "/charge-update",
    icon: HiCloudDownload,
  },

  {
    title: "Upload",
    path: "/upload-neft",
    icon: HiCloudUpload,
  },

  {
    title: "Standing Instruction",
    path: "/standing-instruction",
    icon: HiPencilAlt,
    subNav: [
      {
        title: "Create",
        path: "/standing-instruction/create",
        icon: HiHome,
      },
      {
        title: "Stop",
        path: "/standing-instruction/edit",
        icon: HiHome,
      },
      {
        title: "View",
        path: "/standing-instruction/view",
        icon: HiHome,
      },
    ],
  },
  {
    title: "Reports",
    path: "/reports-amu",
    icon: HiClipboardList,
  },
];

export const tBSuperData: SidebarProps[] = [
  {
    title: "Dashboard",
    path: "/",
    icon: HiHome,
  },
  {
    title: "Charge Update",
    path: "/charge-update",
    icon: HiCloudDownload,
  },
  {
    title: "Reports",
    path: "/reports-amu",
    icon: HiClipboardList,
  },
  {
    title: "NEFT Batch Uploads",
    path: "/supervisor",
    icon: HiCloudUpload,
  },
  {
    title: "View Approved",
    path: "/approved",
    icon: HiOutlineBadgeCheck,
  },
  {
    title: "Outward Neft",
    path: "/outward-neft",
    icon: HiTrendingUp,
  },
];

export const csmData: SidebarProps[] = [
  {
    title: "Dashboard",
    path: "/",
    icon: HiHome,
  },
  {
    title: "Transactions",
    path: "/supervisor",
    icon: HiCloudUpload,
  },

  {
    title: "Reports",
    path: "/general-report",
    icon: HiClipboardList,
  },
  // {
  //   title: "Reports",
  //   path: "/branch-report",
  //   icon: HiClipboardList,
  // },
];

export const supportTeamData: SidebarProps[] = [
  {
    title: "Dashboard",
    path: "/",
    icon: HiHome,
  },
  {
    title: "Reports",
    path: "/reports-amu",
    icon: HiClipboardList,
  },
];

export const amuMakerData: SidebarProps[] = [
  {
    title: "Dashboard",
    path: "/",
    icon: HiHome,
  },

  {
    title: "Standing Instruction",
    path: "/standing-instruction",
    icon: HiPencilAlt,
    subNav: [
      {
        title: "Create",
        path: "/standing-instruction/create",
        icon: HiHome,
      },
      {
        title: "Stop",
        path: "/standing-instruction/edit",
        icon: HiHome,
      },
    ],
  },
  {
    title: "Reports",
    path: "/reports-amu",
    icon: HiClipboardList,
  },
];

export const amuSupervisorData: SidebarProps[] = [
  {
    title: "Dashboard",
    path: "/",
    icon: HiHome,
  },

  {
    title: "Standing Instruction",
    path: "/standing-instruction/view",
    icon: HiPencilAlt,
    subNav: [
      {
        title: "View",
        path: "/standing-instruction/view",
        icon: HiHome,
      },
    ],
  },
  {
    title: "Reports",
    path: "/reports-amu",
    icon: HiClipboardList,
  },
];

export const clientUserData: SidebarProps[] = [
  {
    title: "Dashboard",
    path: "/",
    icon: HiHome,
  },
  {
    title: "Standing Instruction",
    path: "/standing-instruction",
    icon: HiPencilAlt,
    subNav: [
      {
        title: "Create",
        path: "/standing-instruction/create",
        icon: HiHome,
      },
      {
        title: "Stop",
        path: "/standing-instruction/edit",
        icon: HiHome,
      },
      {
        title: "View",
        path: "/standing-instruction/view",
        icon: HiHome,
      },
    ],
  },
  {
    title: "Reports",
    path: "/reports-amu",
    icon: HiClipboardList,
  },
];
