import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import { HiChevronDown, HiChevronUp } from "react-icons/hi2";
import { ReactComponent as EllipseIcon } from "src/assets/svg/el.svg";
import { SidebarProps } from "../../types/Types";

const SidebarLabel = styled.span`
  margin-left: 10px;
  text-decoration: none;
  font-size: 15px;
`;

const SidebarLabelSub = styled.span`
  margin-left: 12px;
  font-size: 15px;
`;
const MainTitle = styled.div`
  display: flex;
  align-items: center;
`;
const DropIcon = styled.div``;

const DropdownLink = styled(Link)`
  height: 55px;
  padding-left: 1.5rem;
  display: flex;
  padding-block: 12px;
  align-items: center;
  text-decoration: none;
  color: #807984;
  font-size: 14px;
  transition: 0.3s color ease;
  &:hover {
    background: #d7cbe2;
    cursor: pointer;
    border-radius: 10px;
    text-decoration: none;
  }
`;

const LinkWrap = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  padding: 14px 12px;
  justify-content: space-between;
  &:hover {
    background: #d7cbe2;
    cursor: pointer;
    border-radius: 10px;
    text-decoration: none;
  }
`;

interface ItemProps {
  item: SidebarProps;
}
const SubMenu: React.FC<ItemProps> = ({ item }) => {
  const [subnav, setSubnav] = useState(false);
  const showSubnav = () => setSubnav(!subnav);

  return (
    <>
      <NavLink
        to={item.path}
        onClick={item.subNav && showSubnav}
        style={({ isActive }) =>
          isActive
            ? {
                display: "flex",
                alignItems: "center",
                width: "100%",
                borderRadius: "10px",
                color: "#5C2684",
                justifyContent: "space-between",
                backgroundColor: "#d7cbe2",
                textDecoration: "none",
              }
            : {
                color: "#464347",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "10px",
                width: "100%",
                textDecoration: "none",
              }
        }
      >
        <LinkWrap>
          <MainTitle>
            <item.icon size={20} />
            <SidebarLabel>{item.title}</SidebarLabel>
          </MainTitle>
          <DropIcon>
            {item.subNav && (
              <>
                {subnav ? (
                  <HiChevronUp color="#807984" />
                ) : (
                  <HiChevronDown color="#807984" />
                )}
              </>
            )}
          </DropIcon>
        </LinkWrap>
      </NavLink>
      {subnav &&
        item?.subNav?.map((item: SidebarProps) => {
          return (
            <DropdownLink to={item.path} key={item.title}>
              <EllipseIcon />
              <SidebarLabelSub>{item.title}</SidebarLabelSub>
            </DropdownLink>
          );
        })}
    </>
  );
};

export default SubMenu;
