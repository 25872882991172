import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { errorFormatter } from "src/utils/errorFormatter";

const localInfo = localStorage.getItem("staffInfo");
const tokenValue = JSON.parse(localInfo || "{}")?.accessToken;

const headers = {
  Authorization: `Bearer ${tokenValue}`,
};

const BASE_URL = process.env.REACT_APP_BASEURL;

const END_POINT = `/Transactions/Initiate`;
export const initiateTransaction = createAsyncThunk<any, any>(
  "data/initiateData",
  async (payload) => {
    const headers = {
      Authorization: `Bearer ${payload?.token}`,
    };
    const requiredData = {
      batchId: payload?.batchId,
      transactionIds: payload?.transactionIds,
    };

    const response = await axios.post(`${BASE_URL}${END_POINT}`, requiredData, {
      headers,
    });
    return response.data;
  }
);

// slice fot posting data upon initiated transaction
const initiateTransactionDetails = createSlice({
  name: "initiate",
  initialState: {
    isLoading: false,
    error: "no error",
    data: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(initiateTransaction.pending, (state) => {
        state.isLoading = true;
        state.error = "still checking";
        state.data = null;
      })
      .addCase(initiateTransaction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = "no error";
        state.data = action.payload;
      })
      .addCase(initiateTransaction.rejected, (state, action) => {
        const message = errorFormatter(action.payload);
        state.isLoading = false;
        state.error = message;
      });
  },
});

export default initiateTransactionDetails.reducer;
