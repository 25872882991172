import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    open: false,
    showNestedModal: false,
    displayNestedModal : false,
    showDeclineModal: false,
    showDeleteModal: false,
    openApproval : false,
}

export const openSlice = createSlice({
    name: "statesToggle",
    initialState,
    reducers: {
        setDisplay: (state) => {
            state.open = !state.open
        },
        openNestedModal: (state) => {
            state.showNestedModal = !state.showNestedModal;
            //state.nestedModalData = action.payload;
          },
          displayApproval : (state) => {
            state.openApproval = !state.openApproval
          },
          displayDeleteModal : (state) => {
            state.showDeleteModal = !state.showDeleteModal
          },
          displayDeclineModal: (state) => {
            state.showDeclineModal = !state.showDeclineModal
          },

          unHideNestedModal : (state) => {
            state.displayNestedModal = !state.displayNestedModal
          },
          closeNestedModal(state) {
            state.showNestedModal = false;
            //state.nestedModalData = {};
          },
        closeModal: (state) => {
            state.open = false
        },
       
    }
})

export const {setDisplay , closeModal, displayDeclineModal,
     unHideNestedModal, displayDeleteModal,
      openNestedModal , displayApproval, closeNestedModal} = openSlice.actions

export default openSlice.reducer