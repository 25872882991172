import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "src/store/store";
import { baseQuery, baseQueryWithAuth, getHeaders } from ".";

const BASE_URL = process.env.REACT_APP_BASEURL;

interface frequencyResponse {
  id: number;
  name: string;
}

interface bankList {
  id: string;
  name: string;
  code: string;
}

interface typeList {
  id: number;
  name: string;
}
export interface siList {
  solId?: string;
  siFrequency?: string;
  ifHoliday?: string;
  nextExecdate?: Date;
  endDate?: string;
  drAccountNo?: string;
  crAccountNo?: string;
  bankName?: string;
  amount?: number;
  narration?: string;
  beneficiaryName?: string;
  senderName?: string;
  drSortCode?: string;
  crSortCode?: string;
  immediateTransfer?: string;
  si?: string;
  stopSi?: string;
  lastProcessedDate?: Date;
  siRef?: string;
  noOfTime?: number;
  noOfTimeExec?: number;
  entryUserId?: null;
  autorizedUserId?: null;
  autorized?: string;
  profileDate: Date;
  tranId: string;
  successOrFailure?: null;
  editUserId?: null;
  edited?: null;
  expectedDate?: Date;
  chargeAccount?: string;
  createdOn?: Date;
  lastModified?: Date;
  createdBy?: null;
  status?: string;
}
export interface SIRes {
  responseCode?: string;
  responseDescription?: string;
  status?: boolean;
  data?: siList[];
}

export interface clearData {
  neftName?: string;
  neftAcctID?: string;
  neftBranchID?: string;
  neftCurrency?: string;
  neftBalance?: number;
}
export interface clearingRes {
  responseCode?: string;
  responseDescription?: string;
  status?: boolean;
  data?: clearData[];
}

export interface accRes {
  responseCode: string;
  responseDescription: string;
  data: string;
  error: string;
}

export interface StandingPayload {
  debitAccount?: string;
  debitAccountName?: string;
  beneficiaryAccount?: string;
  beneficiaryAccountName?: string;
  beneficiaryBank?: string;
  beneficiaryInstitutionCode?: string;
  amount?: number;
  creditNarration?: string;
  debitNarration?: string;
  onHoliday?: boolean;
  instructionType?: number;
  frequency?: number;
  channelCode?: string;
  startDate?: string;
  endDate?: string;
}

interface StandingResponse {
  responseCode?: string;
  responseDescription?: string;
  status?: boolean;
  data?: string;
}
interface StandingRes {
  responseDescription: string;
  data: string;
  status: boolean;
  responseCode: string;
}

interface ApproveInstruction {
  tranId: string;
  isApprove: boolean;
  reason: string;
}

interface updateStanding {
  responseCode: string;
  responseDescription: string;
  status: boolean;
  data: string;
}

export interface reportData {
  BatchId?: string;
  Amount?: number;
  Neftcharge?: number;
  FcmbCharge?: number;
  Vat?: number;
  InitiatingBranchCode?: string;
  Count?: number;
  ApprovalStatus?: string;
  ProcessingStatus?: string;
  CreatedBy?: string;
  ApprovedBy?: string;
  IsChargesDebited?: boolean;
  IsPrincipalAmountDebited?: boolean;
  PostingBatchId?: string;
  CreatedOn?: string;
}

export interface reportResponse {
  responseCode?: string;
  responseDescription?: string;
  status?: boolean;
  data?: reportData[];
}

export interface clearingReportData {
  SolId?: string;
  SiFrequency?: string;
  IfHoliday?: string;
  NextExecDate?: null;
  EndDate?: Date;
  DrAccountNo?: string;
  crAccountNo?: string;
  BankName?: string;
  Amount?: number;
  BeneficiaryName?: string;
  SenderName?: string;
  ImmediateTransfer?: string;
  Si?: string;
  StopSi?: string;
  LastProcessedDate?: null;
  No_Of_Time?: number;
  No_Of_Time_Exec?: number;
  Entry_User_Id?: string;
  Autorized_User_Id?: null;
  ProfileDate?: Date;
  TranId?: string;
  SuccessOrFailure?: null;
  Edit_User_Id?: null;
  Edited?: null;
  ChargeAccount?: string;
}

export interface clearingReportResponse {
  responseCode?: string;
  responseDescription?: string;
  status?: boolean;
  data?: clearingReportData[];
}

export interface editStanding {
  tranId?: number;
  isApprove?: boolean;
  reason?: string;
}

export interface glData {
  transactions?: Transaction[];
  glAccountResponse?: GlAccountResponse;
}

export interface GlAccountResponse {
  neftName?: string;
  neftAcctID?: string;
  neftBranchID?: string;
  neftCurrency?: string;
  neftBalance?: number;
}

export interface Transaction {
  batchId?: string;
  branchCode?: string;
  totalAmount?: number;
}

export interface glResponse {
  responseCode: string;
  responseDescription: string;
  data: glData[];
}

export interface addSweep {
  glAccountDetails?: NeftClearingAccount[];
  neftClearingAccount?: NeftClearingAccount;
}

export interface NeftClearingAccount {
  neftAccountID?: string;
  nefAccountName?: string;
  neftBalance?: number;
}

export interface sweepRes {
  responseCode: string;
  responseDescription: string;
  data: string;
  status: boolean;
  error?: any;
}

const customBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const baseResult = await fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (_headers, { getState }) => {
      const token = (getState() as RootState).StaffDataReducer?.staffInfo
        ?.accessToken;
      const headers = getHeaders(_headers);

      headers.set("Content-Type", "application/json");
      headers.set("accept", "text/plain");
      headers.set("Access-Control-Expose-Headers", "access-token");
      headers.set("Access-Control-Allow-Origin", "*");
      headers.set("Access-Control-Allow-Methods", "*");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
    },
  })(args, api, extraOptions);
  const newResponse: any = {
    ...baseResult,
  };

  const errorCode = newResponse?.data?.statusCode;
  if (errorCode === 401) {
    localStorage.clear();
    window.location.href = "/login";
  }
  return baseResult;
};

export const standingApi = createApi({
  reducerPath: "standingApi",
  baseQuery: baseQueryWithAuth(baseQuery),

  endpoints: (builder) => ({
    //get all charges
    getAllFrequencies: builder.query<frequencyResponse[], void>({
      query: (token) => ({
        url: "/StandingInstructions/Frequency",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),

      transformResponse: (response: { data: frequencyResponse[] }, meta, arg) =>
        response.data,
    }),

    //get all outward
    getAllBanks: builder.query<bankList[], void>({
      query: (token) => ({
        url: "/Admin/Banks",
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      }),
      transformResponse: (response: { data: bankList[] }, meta, arg) =>
        response.data,
    }),

    //get all outward
    getAllTypes: builder.query<typeList[], void>({
      query: (token) => ({
        url: "/StandingInstructions/Type",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      transformResponse: (response: { data: typeList[] }, meta, arg) =>
        response.data,
    }),

    createStanding: builder.mutation<StandingRes, StandingPayload>({
      query: (body) => ({
        url: "/StandingInstructions",
        method: "POST",
        body,
      }),
      // transformResponse: (response: { data: StandingRes }, meta, arg) =>
      //   response.data,
    }),

    //edit standing
    editStanding: builder.mutation<StandingRes, editStanding>({
      query: (body) => ({
        url: "/StandingInstructions/ApproveDeclineInstruction",
        method: "POST",
        body,
      }),
      // transformResponse: (response: { data: StandingRes }, meta, arg) =>
      //   response.data,
    }),

    //get all standing instructions
    getAllSI: builder.query<SIRes, void>({
      query: (token) => ({
        url: "/StandingInstructions/instructions",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      // transformResponse: (response: { data: siList[] }, meta, arg) =>
      //   response.data,
    }),

    getInitiatedSI: builder.query<SIRes, void>({
      query: (token) => ({
        url: "/StandingInstructions/Initiated",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      // transformResponse: (response: { data: siList[] }, meta, arg) =>
      //   response.data,
    }),

    //get a standing instructions
    getSI: builder.mutation<SIRes, { status?: string; token: string }>({
      query: ({ token, status }) => ({
        url: `/StandingInstructions/${status}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      }),
      // transformResponse: (response: { data: siList[] }, meta, arg) =>
      //   response.data,
    }),

    //approve a limit
    approveInstruction: builder.mutation<
      updateStanding,
      Partial<ApproveInstruction>
    >({
      query: (body) => ({
        url: `/StandingInstructions/ApproveDeclineInstruction`,
        method: "PUT",
        body,
      }),
    }),

    //fetch account
    getAccDetails: builder.mutation<
      accRes,
      { accountNo?: string; token: string }
    >({
      query: ({ token, accountNo }) => ({
        url: `/Transactions/verify/debit-account/${accountNo}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      }),
      // transformResponse: (response: { data: siList[] }, meta, arg) =>
      //   response.data,
    }),

    getCAccDetails: builder.mutation<
      accRes,
      { destinationInstitutionCode?: string; accountNumber: string }
    >({
      query: (body) => ({
        url: "/Transactions/verify/credit-account",
        method: "POST",
        body,
      }),
      // transformResponse: (response: { data: StandingRes }, meta, arg) =>
      //   response.data,
    }),

    getAllReports: builder.query<reportData[], void>({
      query: (token) => ({
        url: "/Reports/transactions-report",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      transformResponse: (response: { data: reportData[] }, meta, arg) =>
        response.data,
    }),

    getAllClearingReports: builder.query<clearingReportData[], void>({
      query: (token) => ({
        url: "/Reports/clearing/si-transactions-report",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      transformResponse: (
        response: { data: clearingReportData[] },
        meta,
        arg
      ) => response.data,
    }),

    //supervisor endpoint
    //gl balance
    getAllGLBalance: builder.query<glData[], void>({
      query: (token) => ({
        url: "/Clearing/batch-with-gl-balance",
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      transformResponse: (response: { data: glData[] }, meta, arg) =>
        response.data,
    }),

    //get all clearing neft balance
    getClearingNeftBalance: builder.query<clearData[], void>({
      query: (token) => ({
        url: "/Clearing/neftbalance",
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      transformResponse: (response: any, meta, arg) => response.data.data,
    }),

    //get all clearing neft balance
    addSweep: builder.mutation<sweepRes, addSweep>({
      query: (body) => ({
        url: "/Clearing/sweep",
        method: "POST",
        body,
      }),
      transformResponse: (response: { data: sweepRes }, meta, arg) =>
        response.data,
    }),
    //logout
    logout: builder.mutation<any, any>({
      query: () => ({
        url: "/Security/logout",
        method: "POST",
      }),
    }),

    //all branches
    allBranches: builder.mutation<any, any>({
      query: (token) => ({
        url: "/Services/bank-list",
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
  }),
});

export const {
  useGetAllFrequenciesQuery,
  useGetAllBanksQuery,
  useGetAllTypesQuery,
  useCreateStandingMutation,
  useGetAllSIQuery,
  useGetInitiatedSIQuery,
  useGetSIMutation,
  useApproveInstructionMutation,
  useGetAccDetailsMutation,
  useGetCAccDetailsMutation,
  useGetClearingNeftBalanceQuery,
  useGetAllReportsQuery,
  useGetAllClearingReportsQuery,
  useGetAllGLBalanceQuery,
  useAddSweepMutation,
  useLogoutMutation,
  useAllBranchesMutation,
} = standingApi;
